/** Constants */

/** PageStateType */
export const STATE_READY = 'READY'
export const STATE_SUCCESS = 'SUCCESS'
export const STATE_LOADING = 'LOADING'
export const STATE_REQUESTED = 'REQUESTED'
export const STATE_ERROR = 'ERROR'
export const STATE_PREVIEW = 'STATE_PREVIEW'

/** ActionType */
export const ACTION_UPDATE = 'UPDATE'
export const ACTION_EXTEND = 'EXTEND'
export const ACTION_CREATE = 'CREATE'

/** DisplayModeType */
export const DISPLAY_MODE_STANDARD = 'STANDARD'
export const DISPLAY_MODE_PRESENTATION = 'PRESENTATION'

/** GatedModeType */
export const GATED_MODE_STANDARD = 'No restrictions (public)'
export const GATED_MODE_PASSWORD = 'Password protected'
export const GATED_MODE_EMAIL_GATE = 'Capture emails'

/** ModesType */
export const MODES = {
  [GATED_MODE_STANDARD]: {
    icon: 'rectangle.svg',
    desc: 'No restrictions for your visitors'
  },
  [GATED_MODE_PASSWORD]: {
    icon: 'lock-black.svg',
    desc: 'Make your link private by adding a layer of security'
  },
  [GATED_MODE_EMAIL_GATE]: {
    icon: 'mail.svg',
    desc: 'Capture potential leads by requiring visitors to enter their email address'
  }
}

/** LableType */
export const LABEL_MAP = {
  [ACTION_CREATE]: 'PUBLISH',
  [ACTION_UPDATE]: ACTION_UPDATE
}

/** FileUploadLimit */
export const FILE_UPLOAD_LIMIT = 1

/** UploadFileType */
import { FILE_TYPES } from '../../helpers/file-type'

export const FILE_TYPE_PDF = 'application/pdf'
export const FILE_TYPE_HTML = 'text/html'
export const FILE_TYPE_ZIP = 'application/zip'
export const FILE_TYPE_PHP = 'text/php'
export const isZipFile = (type) =>
  ['application/zip', 'application/x-zip-compressed', 'application/octet-stream'].includes(type)

export const FILE_TYPE_EXCEL = FILE_TYPES.excel.toLowerCase()
export const FILE_TYPE_DOC = FILE_TYPES.doc.toLowerCase()
export const FILE_TYPE_PPT = FILE_TYPES.ppt.toLowerCase()
export const FILE_TYPE_IMAGE = FILE_TYPES.image.toLowerCase()
export const FILE_TYPE_VIDEO = FILE_TYPES.video.toLowerCase()
export const FILE_TYPE_AUDIO = FILE_TYPES.audio.toLowerCase()
export const FILE_TYPE_CODE = FILE_TYPES.code.toLowerCase()
export const FILE_TYPE_LOTTIE = FILE_TYPES.lottie.toLowerCase()
export const FILE_TYPE_EBOOK = FILE_TYPES.ebook.toLowerCase()
export const FILE_TYPE_LATEX = FILE_TYPES.latex.toLowerCase()

export const HTML_FILE = 'HTML'
export const PDF_FILE = 'PDF'
export const ZIP_FILE = 'ZIP'
export const PHP_FILE = 'PHP'

export const EXCEL_FILE = FILE_TYPES.excel.toUpperCase()
export const DOC_FILE = FILE_TYPES.doc.toUpperCase()
export const PPT_FILE = FILE_TYPES.ppt.toUpperCase()
export const IMAGE_FILE = FILE_TYPES.image.toUpperCase()
export const VIDEO_FILE = FILE_TYPES.video.toUpperCase()
export const AUDIO_FILE = FILE_TYPES.audio.toUpperCase()
export const CODE_FILE = FILE_TYPES.code.toUpperCase()
export const LOTTIE_FILE = FILE_TYPES.lottie.toUpperCase()
export const EBOOK_FILE = FILE_TYPES.ebook.toUpperCase()
export const LATEX_FILE = FILE_TYPES.latex.toUpperCase()

/** Interface Form */
export const defaultAction = {
  action: 'CREATE', // ActionType
  onSubmit: () => {
    return
  }, // Callback
  validate: () => {
    return
  }, // Callback

  productId: '' // String
}

export const defaultModalValues = {}

export const defaultInitialDomainValues = {
  subdomain: '', // String
  domainSuffix: '', // String
  customDomains: [], // Array
  tlds: [] // Array
}

export const defaultInitialUploadValues = {
  selectedFile: '', // File
  addedFiles: [], // File[]
  displayMode: '', // DisplayModeType
  fileUploadLimit: 0, // Int
  progress: 0 // Int
}

export const defautlInitialToggleButtonValues = {
  passwordProtected: false, // Boolean
  password: '', // String
  disableIndexing: false, // Boolean
  disableExportPdf: false, // Boolean
  siteConfiguration: false, // Boolean
  errorPath: '' // String
}
