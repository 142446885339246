import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import * as Yup from 'yup'
import CustomModal from 'components/CustomModal/CustomModal'

const schema = Yup.object({
  school: Yup.string().required('First Name is required'),
  email: Yup.string().email('Email is invalid').required('Email is required')
})

const StudentVerification = ({ show, onHide, onContinue }) => {
  const onSubmit = ({ email, school }) => onContinue(email, school)

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus={false}
    >
      <div className="p-3">
        <h4 className="mb-4" style={{ color: '#6e2f9a' }}>
          <b>Sign up to Plus Student</b>
        </h4>
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={{}}>
          {({ handleSubmit, handleChange, values }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Control
                    className="mt-2"
                    name="school"
                    type="text"
                    placeholder="School name"
                    value={values.school}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="school" component="div" className="validation-error" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    className="mt-2"
                    name="email"
                    type="text"
                    placeholder="Student email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="email" component="div" className="validation-error" />
                </Col>
              </Row>
              <Button className="ms-auto me-auto mt-3 float-right" type="submit">
                Continue
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </CustomModal>
  )
}

export default StudentVerification
