import React, { useState, useEffect } from 'react'
import { Tab, Tabs, Dropdown } from 'react-bootstrap'
import StyledDropzone from '../StyledDropzone'
import TemplateSelector from '../TemplateSelector/TemplateSelector'
import CustomTooltip from '../CustomTooltip'
import { DROPZONE_ACCEPTED_FILES } from '../../constants'
import {
  imageExtensions,
  excelExtensions,
  docExtensions,
  ebookExtensions,
  pptExtensions,
  pdfExtensions,
  htmlExtensions,
  phpExtensions,
  zipExtensions,
  videoExtensionsBrowserNative,
  audioExtensionsBrowserNative,
  codeExtensions,
  lottieExtensions,
  latexExtensions
} from 'helpers/file-type'

// Define main tab mappings
const contentTypes = {
  html: 'code',
  images: 'images',
  documents: 'documents',
  code: 'code'
}

const fileCategories = {
  documents: {
    title: 'Documents',
    tooltip:
      'PDF, DOCX, XLSX, CSV and <a href="https://helpdesk.tiiny.host/en/article/what-type-of-file-formats-do-you-support-na0784/">more</a>'
  },
  images: {
    title: 'Images',
    tooltip:
      'JPEG, PNG, SVG, WEBP and <a href="https://helpdesk.tiiny.host/en/article/what-type-of-file-formats-do-you-support-na0784/">more</a>'
  },
  code: {
    title: 'Code',
    tooltip:
      'HTML, PHP, ZIP files with code and <a href="https://helpdesk.tiiny.host/en/article/what-type-of-file-formats-do-you-support-na0784/">more</a>'
  },
  more: {
    title: 'More',
    extensions: [
      ...imageExtensions,
      ...excelExtensions,
      ...docExtensions,
      ...ebookExtensions,
      ...pptExtensions,
      ...pdfExtensions,
      ...htmlExtensions,
      ...phpExtensions,
      ...zipExtensions,
      ...videoExtensionsBrowserNative,
      ...audioExtensionsBrowserNative,
      ...codeExtensions,
      ...lottieExtensions,
      ...latexExtensions
    ]
  }
}

const FileTabHeader = ({ title, tooltip }) =>
  tooltip ? (
    <CustomTooltip label={tooltip} isClickable={true} hoverDelay={500}>
      <span>{title}</span>
    </CustomTooltip>
  ) : (
    <span>{title}</span>
  )

const MoreTabDropdown = ({ onSelect, show, setShow, selectedFormat }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const allExtensions = [...fileCategories.more.extensions].sort()

  const filteredExtensions = allExtensions.filter((ext) => ext.toLowerCase().includes(searchTerm.toLowerCase()))

  return (
    <Dropdown show={show} onToggle={(isOpen) => setShow(isOpen)} className="more-tab-dropdown">
      <Dropdown.Toggle
        variant="link"
        className="nav-link p-0 border-0 shadow-none outline-none"
        style={{ boxShadow: 'none' }}
      >
        {selectedFormat ? selectedFormat.toUpperCase() : 'More'}
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 p-0">
        <div className="p-1">
          <input
            className="w-100 border-0 border-bottom"
            style={{
              outline: 'none',
              backgroundColor: 'transparent'
            }}
            placeholder="Search formats..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div style={{ maxHeight: '256px', overflowY: 'auto' }}>
          {filteredExtensions.map((extension) => (
            <Dropdown.Item key={extension} onClick={() => onSelect(extension)}>
              {extension.toUpperCase()}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const ContentSelector = ({ className, onDrop, onTemplateSelected, acceptedFiles = DROPZONE_ACCEPTED_FILES }) => {
  // Get the content parameter from URL
  const urlParams = new URLSearchParams(window.location.search)
  const contentParam = urlParams.get('content')?.toLowerCase()

  // First check if it's in contentTypes (main tabs)
  const mappedTab = contentTypes[contentParam]

  // If not in contentTypes, check if it's a specific format for More
  const isSpecificFormat = !mappedTab && fileCategories.more.extensions.includes(contentParam)

  // Set initial states based on priority checking
  const defaultTab = mappedTab || (isSpecificFormat ? 'more' : 'documents')
  const defaultFormat = isSpecificFormat ? contentParam : null

  const [activeTab, setActiveTab] = useState(defaultTab)
  const [selectedFormat, setSelectedFormat] = useState(defaultFormat)
  const [showDropdown, setShowDropdown] = useState(false)

  const handleTabSelect = (key) => {
    if (key !== 'more') {
      setActiveTab(key)
      setSelectedFormat(null)
    }
  }

  const handleFormatSelect = (format) => {
    setSelectedFormat(format)
    setActiveTab('more')
    setShowDropdown(false)
  }

  return (
    <div className={`container-dropzone ${className || ''}`}>
      <Tabs id="content-selector" activeKey={activeTab} onSelect={handleTabSelect} className="mb-3 justify-center">
        {Object.entries(fileCategories).map(([key, { title, tooltip }]) => {
          if (key === 'more') {
            return (
              <Tab
                key={key}
                eventKey={key}
                title={
                  <MoreTabDropdown
                    onSelect={handleFormatSelect}
                    show={showDropdown}
                    setShow={setShowDropdown}
                    selectedFormat={selectedFormat}
                  />
                }
              >
                <StyledDropzone
                  onDrop={onDrop}
                  onTemplateSelected={onTemplateSelected}
                  acceptedFiles={acceptedFiles}
                  testId={`StyledDropzone-input-upload-file-${key}`}
                />
              </Tab>
            )
          }
          return (
            <Tab key={key} eventKey={key} title={<FileTabHeader title={title} tooltip={tooltip} />}>
              <StyledDropzone
                onDrop={onDrop}
                onTemplateSelected={onTemplateSelected}
                acceptedFiles={acceptedFiles}
                testId={`StyledDropzone-input-upload-file-${key}`}
              />
            </Tab>
          )
        })}
        <Tab eventKey="examples" title="Examples">
          <TemplateSelector onClick={onTemplateSelected} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default ContentSelector
