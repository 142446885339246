import React from 'react'
import styles from './MoreOptions.module.css'
import UpgradeBadge from '../../../UpgradeBadge/UpgradeBadge'
import { isCustomDomain } from '../../../../utils/general'
import { isPDF, addUpgradeReason } from '../../../../utils/general'

const Item = ({ icon, alt, label, onClick, disabled, onUpgrade, hidden }) => {

  const handleUpgrade = () => {
    addUpgradeReason(label)
    onUpgrade()
  };

  if (hidden) return null

  return (
    <div className={`${styles.contentBox} ${disabled ? styles.disabled : ''}`} onClick={!disabled ? onClick : null}>
      <img src={`/assets/icons/${icon}`} alt={alt} className={styles.img} />
      <span className={styles.contentText}>{label}</span>
      <UpgradeBadge show={disabled} onUpgrade={handleUpgrade} />
    </div>
  )
}

const MoreOptions = ({
  onEditHTML,
  onEditPDF,
  onArchive,
  onDownload,
  onAnalytics,
  onQRCode,
  onDelete,
  onCopy,
  onCopyDirectLink,
  onReplaceFile,
  onAddCustomDomain,
  type,
  onEmbed,
  onCustomisePasswordPage,
  passwordProtected,
  enableEmailGate,
  linkPreview,
  onShare,
  trial,
  link,
  onUpgrade,
  disabled
}) => {
  const phpFile = link.includes('tiiny.io')
  const customDomainLink = isCustomDomain(link)

  if (disabled) {
    // Render only the "Delete" item if disabled is true
    return (
      <div className={styles.menu}>
        <div className={styles.contentContainer}>
          <Item icon="trash.svg" alt="delete" label="Delete" onClick={onDelete} data-testid="delete-option" />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.menu}>
      <div className={styles.contentContainer}>
        <Item icon="copy-link.svg" alt="copy link" label="Copy link" onClick={onCopy} hidden={linkPreview} />
        <Item
          icon="download-file.svg"
          alt="downloadlink"
          label="Copy PDF download link"
          onClick={onCopyDirectLink}
          hidden={trial || phpFile || linkPreview || !isPDF({ type })}
        />
        <Item icon="share.svg" alt="sharelink" label="Share link" onClick={onShare} hidden={trial} />
        <Item
          icon="qr-code.svg"
          alt="qrcode"
          label="View QR code"
          onClick={onQRCode}
          disabled={linkPreview}
          onUpgrade={onUpgrade}
        />
        <Item
          icon="embed.svg"
          alt="embed"
          label="Embed this link"
          onClick={onEmbed}
          disabled={linkPreview}
          onUpgrade={onUpgrade}
        />
        {!customDomainLink && (
          <Item icon="custom-domain.svg" alt="Domain" label="Publish to custom domain" onClick={onAddCustomDomain} />
        )}
      </div>
      {!phpFile && (
        <div className={styles.contentContainer}>
          <Item icon="chart-bar.svg" alt="analytics" label="View analytics" onClick={onAnalytics} />
        </div>
      )}
      <div className={styles.contentContainer}>
        <Item
          icon="edit.svg"
          alt="edit"
          label={type?.includes('pdf') ? 'Edit PDF' : 'Edit site'}
          onClick={type?.includes('pdf') ? onEditPDF : onEditHTML}
          disabled={linkPreview}
          onUpgrade={onUpgrade}
          hidden={phpFile}
        />
        <Item icon="replace.svg" alt="replace" label="Replace existing file" onClick={onReplaceFile} />
        {(passwordProtected || enableEmailGate) && (
          <Item
            icon="settings.svg"
            alt="Customize password page"
            label={`Customize ${enableEmailGate ? 'email capture' : 'password'} page`}
            onClick={onCustomisePasswordPage}
            hidden={phpFile}
          />
        )}
      </div>
      <div className={styles.contentContainer}>
        <Item icon="download-white.svg" alt="download" label="Download copy" onClick={onDownload} hidden={phpFile} />
        <Item icon="archive-box.svg" alt="archive" label="Archive" onClick={onArchive} hidden={phpFile} />
        <Item icon="trash.svg" alt="delete" label="Delete" onClick={onDelete} data-testid="delete-option" />
      </div>
    </div>
  )
}

export default MoreOptions
