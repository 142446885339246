import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Form, InputGroup, Spinner, Alert } from 'react-bootstrap'
import { cancelSubscription } from '../../../services/manage'
import CustomTooltip from '../../CustomTooltip'
import { copyToClipboard, getSubdomainAndTLD } from 'utils/general'

const OPTIONS = [
  {
    id: 'A',
    text: 'I was working on a one-off project and only needed tiiny host for a month'
  },
  {
    id: 'B',
    text: 'Tiiny Host lacked a feature I was looking for'
  },
  {
    id: 'C',
    text: "There were technical issues / I wasn't able to link a custom domain"
  },
  {
    id: 'D',
    text: 'It was too expensive for me'
  },
  {
    id: 'E',
    text: 'Something else'
  },
  {
    id: 'F',
    text: 'My link was blocked whilst sending an email or sharing on social platforms'
  }
]

const getShuffledOptions = () => {
  return [...OPTIONS].sort(() => Math.random() - 0.5)
}

const CancellationStage = ({ sites = [], onUpgrade, onBack, onHide, setHasError, onBookUserResearchCall }) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const [otherText, setOtherText] = useState('')
  const [showError, setShowError] = useState(false)
  const history = useHistory()

  const urlParams = getSubdomainAndTLD({ site: { subdomain: sites[0]?.subdomain } })
  const redirect_url = `${urlParams?.subdomain}.tiiny.xyz/?d=${urlParams?.tld?.replace(/^\./, '')}`
  const learn_more =
    'https://helpdesk.tiiny.host/en/article/your-tiiny-host-link-is-blocked-whilst-sharing-it-on-outlookfacebooklinkedin-or-other-apps-19nndl6/'

  const onSubmit = () => {
    if (otherText && otherText.length < 30) {
      setShowError(true)
      return
    }
    setLoading(true)
    cancelSubscription({ value, otherText })
      .then(() => {
        alert('Your subscription has been cancelled')
        if (value === 'A') {
          onBookUserResearchCall()
        } else {
          history.replace({ pathname: '/manage' })
          window.location.reload()
          onHide()
        }
      })
      .catch((e) => {
        console.error(e)
        setHasError(true)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <h1 className="modalTitle">Cancel your subscription</h1>
      <div className="mb-3 grey">Please tell us why you would like to cancel your subscription:</div>
      <Form>
        <div className="mb-3">
          <Form.Group controlId="cancel-reason">
            <Form.Control as="select" value={value} onChange={(e) => setValue(e.target.value)}>
              <option value="">Please select a reason</option>
              {getShuffledOptions().map((option, index) => (
                <option key={index} value={option.id}>
                  {option.text}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {['B', 'C', 'E'].includes(value) && (
            <>
              <div className="mt-3 mb-2 ms-1 me-1 grey">
                We're always looking to improve, can you please share more?
              </div>
              <InputGroup className="input-group">
                <Form.Control
                  onChange={(t) => {
                    setOtherText(t?.target?.value)
                    setShowError(false)
                  }}
                  placeholder="Please explain in detail..."
                  rows={5}
                  minLength={30}
                  autoFocus={true}
                  type="text"
                  as="textarea"
                  isInvalid={showError}
                  value={otherText}
                />
                {showError && (
                  <Form.Control.Feedback type="invalid">
                    Please provide more detailed feedback, it really helps us improve.
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </>
          )}
          {value === 'D' && (
            <Alert className="mt-2 bold" variant="warning">
              Save money and{' '}
              <a onClick={() => onUpgrade(false)} className="pl-4 link color-purple">
                change plan →
              </a>
            </Alert>
          )}
          {value === 'F' && !!sites.length && (
            <Alert className="mt-2" variant="warning">
              <b>
                Have you tried sharing with our unique Share Links? (
                <a href={learn_more} target="_blank" rel="noopener noreferrer" className="text-black">
                  Learn more
                </a>
                )
              </b>
              <div className="d-flex mt-2">
                E.g.
                {sites[0] && (
                  <>
                    <a href={`https://${redirect_url}`} target="_blank" rel="noopener noreferrer" className="ms-2">
                      {redirect_url}
                    </a>
                    <CustomTooltip label="Copy">
                      <img
                        src="/assets/icons/copy.png"
                        height={16}
                        width={16}
                        onClick={() => copyToClipboard(`https://${redirect_url}`)}
                        className="d-inline-block cursor-pointer mx-2"
                        alt="Copy"
                      />
                    </CustomTooltip>
                  </>
                )}
              </div>
            </Alert>
          )}
        </div>
        <Button
          disabled={
            loading ||
            !((value && !['B', 'C', 'E'].includes(value)) || (['B', 'C', 'E'].includes(value) && otherText?.length > 5))
          }
          className="float-right"
          onClick={onSubmit}
          variant="purple"
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Cancel account'}
        </Button>
        <Button className="float-right mx-2" variant="outline-primary" onClick={onBack}>
          Back
        </Button>
      </Form>
    </div>
  )
}

export default CancellationStage
