import { Modal } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setPreviewData } from 'Manage/actions'
import styles from './PreviewModal.module.css'
import CTAButton from '../CTAButton/CTAButton'
import { onUpgradePlan, convertToBytes, addUpgradeReason } from 'utils/general'
import CountdownTimer from 'components/CountDownTimer/CountDownTimer'
import { MAX_FREE_FILE_SIZE, linkPreviewTypes } from '../../constants'
import { uploadedFileTooBig } from 'utils/sendy'
import CustomModal from 'components/CustomModal/CustomModal'

const PreviewModal = ({ onHide, show, site }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(true)
  const history = useHistory()

  const onClose = () => {
    dispatch(setPreviewData(undefined))
    uploadedFileTooBig()
    history.push({ pathname: '/manage' })
    onHide()
  }

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  const onPublish = (site) => {
    const fileSize = site?.size ? site?.size : convertToBytes(MAX_FREE_FILE_SIZE)
    addUpgradeReason('Preview Mode')
    onUpgradePlan({ fileSize: fileSize }, dispatch)
    onClose()
  }

  const getDomain = (siteRecord) => {
    const subDomain = siteRecord?.subdomain || siteRecord?.link
    const domain = siteRecord || ''
    return subDomain || domain
  }

  const isPHP = (site) => {
    return site && getDomain(site)?.endsWith('tiiny.io')
  }

  const isMalicious = (site) => {
    return site && site.linkPreviewType === linkPreviewTypes.MALICIOUS_CONTENT
  }

  return (
    <CustomModal
      size="xl"
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus={false}
    >
      <Modal.Body className={styles.body}>
        <iframe className={styles.iframe} src={`https://${getDomain(site)}`} title="Link Preview" allowFullScreen />
      </Modal.Body>
      <div className={`${styles.container} ${isOpen ? styles['slide-top'] : styles['slide-bottom']}`}>
        {isOpen ? (
          <div className={styles.open}>
            <div className={styles.dropdown} onClick={toggleAccordion}>
              <img src="/assets/icons/down-arrow.svg" alt="down-arrow" />
            </div>
            <div className={`${styles.content}`}>
              <div className={styles.timer}>{site?.created && <CountdownTimer creationTime={site.created} />}</div>
              <div className={styles.heading}>Link Preview</div>
              <div className={styles.subHeading}>
                <span className={styles.subTitle}>
                  {isMalicious(site) ? (
                    <>
                      This content can't be hosted on the free plan.{' '}
                      <a
                        href="https://helpdesk.tiiny.host/en/article/were-unable-to-host-this-type-of-content-on-our-free-plan-1l9v94w/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more
                      </a>
                    </>
                  ) : isPHP(site) ? (
                    'PHP uploads are not available on the free plan'
                  ) : (
                    "Your content is larger than your plan's limit"
                  )}
                </span>
              </div>
              <div className={styles.btnCon}>
                <CTAButton
                  className={styles.ctaBtn}
                  onClick={() => onPublish(site)}
                  label="Upgrade to get link"
                  variant="primary-gradient-round"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.closed}>
            <div className={styles.dropdown} onClick={toggleAccordion}>
              <img src="/assets/icons/up-arrow.svg" alt="down-arrow" />
            </div>
            <div className={`${styles.contentCollapsed}`}>
              <div>
                {site?.created && <CountdownTimer creationTime={site.created} />}
                <div className={styles.heading2}>Link Preview</div>
                <p className={styles.subTitle}>
                  {isMalicious(site) ? (
                    <>
                      This content can't be hosted on the free plan.{' '}
                      <a
                        href="https://helpdesk.tiiny.host/en/article/were-unable-to-host-this-type-of-content-on-our-free-plan-1l9v94w/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more
                      </a>
                    </>
                  ) : isPHP(site) ? (
                    'PHP uploads are not available on the free plan'
                  ) : (
                    "Your content is larger than your plan's limit"
                  )}
                </p>
              </div>
              <div className={styles.timer}></div>
              <div className={`${styles.btnCon} justify-content-end`}>
                <CTAButton
                  className={styles.ctaBtn}
                  onClick={() => onPublish(site)}
                  label="Upgrade to get link"
                  variant="primary-gradient-round"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </CustomModal>
  )
}

export default PreviewModal
