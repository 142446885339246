import React from 'react'
import { Button } from 'react-bootstrap'

const ResearchCall = ({ onHide }) => {
  const onBook = () => {
    window.open('https://cal.com/kourosh/tiiny-host-cancellation-feedback', '_blank')
    onHide()
  }

  return (
    <div>
      <h2 className="mb-3 bold color-purple-2 font-22">Would you like a 50% refund?</h2>
      We'd like to offer you a 50% refund of your last month's bill for a 15-minute feedback call.
      <div className="mt-3">
        <Button className="ms-2 me-auto float-end" variant="primary" onClick={onBook}>
          Book call
        </Button>
        <Button className="me-auto float-end" variant="outline-secondary" onClick={onHide}>
          No thanks
        </Button>
      </div>
    </div>
  )
}

export default ResearchCall
