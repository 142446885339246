import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Button, Spinner } from 'react-bootstrap'
import ConfirmationModal from 'components/ConfirmationModal'
import { PLANS_ID } from '../../../constants/plans'
import { getAPIKey, manageBilling } from '../../../services/manage'
import { copyToClipboard, hasFeature } from '../../../utils/general'
import IconButton from '../../IconButton'
import CustomTooltip from '../../CustomTooltip'
import { fetchUserData, setAPIKey, setAccountPasswordModal, showMessageModal } from '../../../Manage/actions'
import { F_API_KEY } from '../../../constants/plans/constants'
import { getAreaCurrency, TIMEZONE } from '../../../constants/pricing/currency'
import { uncancelSubscription } from '../../../services/manage'
import { useLocation, useHistory } from 'react-router-dom'
import DEEP_LINK from '../../../utils/deepLinks'
import { ACCOUNT_STATUS } from '../../../constants'

const fetchBillingLink = () => {
  manageBilling()
    .then((res) => {
      const url = res.data.session.url
      if (url) {
        window.open(url, '_blank')
      }
    })
    .catch((e) => {
      console.error(e)
      alert('Sorry, an error has occurred')
    })
}

export const isIndia = getAreaCurrency(TIMEZONE) === '₹'

const Details = ({
  cardType,
  productId,
  lastFour,
  status,
  amount,
  interval,
  nextBilling,
  email,
  apiKey,
  currency = '$',
  stripeCustomer,
  updateUrl,
  onCancel,
  onUpgrade,
  dispatch
}) => {
  const [generatingKey, setGeneratingKey] = useState(false)
  const [modalConfirmationShow, setModalConfirmationShow] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState('')
  const planLabel = PLANS_ID[productId]?.label
  const trialAccount = !productId

  const location = useLocation()
  const history = useHistory()

  const isCancelling = status?.startsWith('CANCELLING') || status === 'CANCELLED'

  useEffect(() => {
    if (location.pathname === DEEP_LINK.REACTIVATE_SUB) {
      reactivateSubscription()
      history.replace({ pathname: '/manage' })
    }
  }, [])

  const generateAPIKey = () => {
    setGeneratingKey(true)
    getAPIKey()
      .then(({ data: { key } }) => {
        dispatch(setAPIKey(key))
      })
      .catch((e) => {
        console.error(e)
        alert('Sorry, unable to generate key')
      })
      .finally(() => setGeneratingKey(false))
  }

  const handleUncancelClick = () => {
    setConfirmMessage('Are you sure you want to uncancel your subscription?') // Set confirmation message
    setModalConfirmationShow(true)
  }

  const reactivateSubscription = () => {
    if (isCancelling) {
      uncancelSubscription()
        .then(() => {
          dispatch(fetchUserData(false))
          dispatch(showMessageModal({ title: 'Subscription Reactivated' }))
        })
        .catch((error) => {
          console.error(error)
          alert('Failed to uncancel subscription.')
        })
        .finally(() => {
          setModalConfirmationShow(false)
        })
    }
  }

  return (
    <>
      <ConfirmationModal
        title="Uncancel Subscription"
        message={confirmMessage}
        show={modalConfirmationShow}
        handleNegative={() => setModalConfirmationShow(false)}
        handlePositive={reactivateSubscription}
      />
      <h4 className="color-purple mb-3 bold">Account</h4>
      <table className="account-table mb-3 w-100 ms-auto me-auto grey">
        <tbody>
          <tr className="pb-4">
            <td>Email</td>
            <td className="text-end bold">{email}</td>
          </tr>

          <tr className="pb-4">
            <td>Password</td>
            <td className="text-end">
              <a className="link" onClick={() => dispatch(setAccountPasswordModal(true))}>
                Set password
              </a>
            </td>
          </tr>

          {!trialAccount && (
            <tr>
              <td>Status</td>
              <td className="text-end font-weight-bold">
                {status || 'Incomplete'}{' '}
                {isCancelling && (
                  <span>
                    (
                    <a className="link color-purple" onClick={handleUncancelClick}>
                      Uncancel
                    </a>
                    )
                  </span>
                )}
              </td>
            </tr>
          )}
          <tr>
            <td>Current plan</td>
            {!trialAccount && (
              <td className="text-end bold">
                {planLabel ? `${planLabel} - ` : ''} {currency.toUpperCase()} {isIndia ? amount : amount / 100 || '-'} /{' '}
                {interval || '-'}
              </td>
            )}
            {trialAccount && <td className="text-end bold">Free</td>}
          </tr>

          {nextBilling && status?.toUpperCase() === ACCOUNT_STATUS.ACTIVE && (
            <tr>
              <td>Next billing date</td>
              <td className="text-end bold">
                {isNaN(nextBilling) ? nextBilling : moment.unix(nextBilling).format('DD MMM YY')}
              </td>
            </tr>
          )}
          {!trialAccount && (
            <tr>
              <td>Payment method</td>
              <td className="text-end bold">
                {cardType && `${cardType.toUpperCase()}`} {lastFour && `•••• ${lastFour}`}
              </td>
            </tr>
          )}
          <tr>
            <td className="pt-2">
              API Key
              <CustomTooltip label="API key for integrations (e.g. Zapier)">
                <img className="ms-2 me-3" src="/assets/icons/info.svg" height="15" />
              </CustomTooltip>
            </td>
            {hasFeature(productId, F_API_KEY) && (
              <td className="text-end font-weight-bold pt-2">
                {apiKey ? (
                  <div className="flex justify-content-end">
                    <span className="d-inline-block text-truncate" style={{ maxWidth: 150 }}>
                      {apiKey}
                    </span>
                    <IconButton
                      className="d-inline-block"
                      src="/assets/icons/copy.png"
                      onClick={() => copyToClipboard(apiKey)}
                      tooltip="Copy key"
                    />
                  </div>
                ) : (
                  <>
                    {generatingKey && <Spinner animation="border" size="sm" />}
                    {!generatingKey && (
                      <a onClick={generateAPIKey} className="link color-purple">
                        Generate
                      </a>
                    )}
                  </>
                )}
              </td>
            )}
            {!hasFeature(productId, F_API_KEY) && (
              <td className="text-end font-small">
                <i>Requires upgrade</i>
              </td>
            )}
          </tr>
        </tbody>
      </table>
      {productId && (
        <div className="text-end">
          <Button size="sm" variant="outline-primary" onClick={() => onUpgrade(false)}>
            Change plan →
          </Button>
        </div>
      )}
      {stripeCustomer && (
        <div className="link color-purple text-decoration-none" onClick={fetchBillingLink}>
          Manage billing →
        </div>
      )}
      {!stripeCustomer && !trialAccount && (
        <div>
          <a className="link color-purple text-decoration-none" href={updateUrl} target="_blank" rel="noreferrer">
            Update details →
          </a>
        </div>
      )}
      {status?.toLowerCase() === 'active' && (
        <div>
          <a className="link color-purple text-decoration-none" onClick={onCancel}>
            Cancel subscription →
          </a>
        </div>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(undefined, mapDispatchToProps)(Details)
