import React, { useState } from 'react'
import { Button, Modal, Spinner, FormControl, InputGroup } from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import api from 'utils/api'
import { API_ROOT } from '../../../constants'
import CustomModal from 'components/CustomModal/CustomModal'

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/

const STAGE_SET_PASSWORD = 'SET_PASSWORD'
const STAGE_OTP = 'OTP'
const STAGE_SUCCESS = 'SUCCESS'

const AccountPasswordModal = ({ show, onHide, userProfile }) => {
  const [stage, setStage] = useState(STAGE_SET_PASSWORD)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (values) => {
    if (stage === STAGE_SUCCESS) {
      onHideAction()
      return
    }

    setIsLoading(true)
    try {
      const { email, password, otp } = values
      const {
        data: { status }
      } = await api.post(`${API_ROOT}/account/update/password`, { email, password, otp })

      if (status === 'PASSWORD_UPDATED') setStage(STAGE_SUCCESS)
      else if (status === 'OTP_FAILED') alert('Incorrect verification code')
      else setStage(STAGE_OTP)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const onHideAction = () => {
    setStage(STAGE_SET_PASSWORD)
    onHide()
  }

  const validate = (values) => {
    const errors = {}
    const { password, confirmPassword } = values

    if (password !== confirmPassword) {
      errors.password = `Passwords must match.`
      return errors
    }

    if (!passwordRegex.test(password)) {
      errors.password = 'Password must be 8 characters, with uppercase and lowercase letters.'
    }

    return errors
  }

  const initialValues = {
    password: '',
    confirmPassword: ''
  }

  return (
    <CustomModal show={show} onHide={onHideAction} aria-labelledby="contained-modal-title-vcenter" centered>
      <div className="p-4">
        <h4 className="color-purple mb-3 bold">Set password</h4>
        <div className="dark-grey">
          {stage === STAGE_SET_PASSWORD && 'Password must be 8 characters, with uppercase and lowercase letters.'}
          {stage === STAGE_OTP && "We've emailed you a verification code."}
          {stage === STAGE_SUCCESS && 'Your password has been successfully updated.'}
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values)}
          validate={(values) => validate(values)}
          validateOnBlur={false}
          validateOnChange={false}
        >
          <Form className="mt-3">
            {stage === STAGE_SET_PASSWORD && (
              <>
                <InputGroup>
                  <div className="w-100">
                    <Field
                      className="w-100"
                      type="password"
                      name="password"
                      as={FormControl}
                      placeholder="Password"
                      aria-label="Password"
                    />
                    <Field
                      className="w-100 mt-2"
                      type="password"
                      name="confirmPassword"
                      as={FormControl}
                      placeholder="Confirm password"
                      aria-label="Confirm password"
                    />
                  </div>
                </InputGroup>
                <ErrorMessage name="password" component="div" className="validation-error p-2" />
              </>
            )}
            {stage === STAGE_OTP && (
              <InputGroup>
                <div className="w-100">
                  <Field
                    className="w-100"
                    type="text"
                    name="otp"
                    as={FormControl}
                    placeholder="Enter verification code"
                    aria-label="Verification code"
                  />
                </div>
              </InputGroup>
            )}
            <Button className="action-btn mt-3" type="submit" disabled={isLoading}>
              {stage === STAGE_SET_PASSWORD && 'Next'}
              {stage === STAGE_OTP && 'Update password'}
              {stage === STAGE_SUCCESS && 'Done'}
              {isLoading && <Spinner className="ms-2" animation="border" size="sm" />}
            </Button>
          </Form>
        </Formik>
      </div>
    </CustomModal>
  )
}

export default AccountPasswordModal
