import React, { useState, useEffect } from 'react'
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setQrCodeData, setQrCodeModal, setEmbedData } from 'Manage/actions'
import { getDirectFileLink } from '../../../utils/general'
import Share from 'components/ShareModal/Share'
import { trackUploadFileCount } from 'utils/sendy'
import { setShowPaymentModal, showUpgradeCardModal, UPGRADE_QR_PAYLOAD } from 'actions'
import { PLANS } from 'constants/plans'
import * as Sentry from '@sentry/react'
import { UPGRADE_OPTIONS } from '../../SuccessBox/FirstTimeSuccessModal'
import IconButton from 'components/IconButton'

import './SuccessBox.css'

/**
 * @todo : make this component dynamic for other use cases (later maybe)
 */

const SuccessBox = ({ userProfile, site, files, onClose, onReset }) => {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(false)

  if (!site) {
    Sentry.captureException(
      { userProfile, site, files, onClose, onReset },
      {
        tags: {
          section: 'load-site-error'
        }
      }
    )
  }
  const { subdomain, passwordProtected, type, filename, lastUpdated, created } = site
  const { productId } = userProfile
  const trialAccount = !userProfile.productId

  const getUpgradeOptions = () => {
    return UPGRADE_OPTIONS[site.siteType] || UPGRADE_OPTIONS.default
  }

  const handleClose = () => {
    onClose()
    trackUploadFileCount()
  }

  const onUpgrade = (planId) => {
    dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: planId }))
  }

  const onQRCodeClick = () => {
    if (userProfile.productId) {
      dispatch(setQrCodeModal(true))
      dispatch(setQrCodeData(site))
    } else {
      dispatch(
        showUpgradeCardModal({
          ...UPGRADE_QR_PAYLOAD,
          onClick: () => dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.TINY.id }))
        })
      )
    }
  }

  /**  @todo: Success box for processing sites */
  useEffect(() => {
    if (site?.linkStatus?.toLowerCase() === 'processing') {
      setProcessing(true)
    } else setProcessing(false)
  }, [site?.linkStatus])

  return (
    <Container fluid>
      <Row className={trialAccount ? 'flex-column-reverse flex-lg-row' : ''}>
        {trialAccount && (
          <Col lg={5} className="bg-light-grey p-4 slide-left features-panel">
            <h2 className="mb-2 color-purple-2 font-22 mb-lg-2">
              <b>Upgrade for more</b>
            </h2>
            <span className="success_subheader">Do more with your project.</span>
            {/* ... (other content in this column remains the same) */}
            <div className="mt-3">
              {getUpgradeOptions().map((option, index) => (
                <Button
                  key={index}
                  variant="outline-primary"
                  className="w-100 text-start mt-3 feature-btn"
                  onClick={() => {
                    onUpgrade(option.plan)
                  }}
                >
                  <img src={`assets/icons/${option.icon}`} height={16} alt={option.icon} />
                  <span className="black ms-2">{option.label}</span>
                </Button>
              ))}
            </div>
          </Col>
        )}
        <Col lg={trialAccount ? 7 : 12} className={`p-4 bg-white ${trialAccount ? 'rounded-end' : 'rounded-2'}`}>
          <div className="success-box">
            <div className="float-right" onClick={handleClose}>
              <img className="exit" src="/delete.png" alt="quit" />
            </div>
            <h3 className="bold">Success</h3>
            <img className="celebrate" src="/celebrate.gif" alt="celebrate" />
            <div>
              <h5 className="domain d-flex align-items-center justify-content-center">
                <a
                  href={`https://${subdomain.replace(/\/$/, '')}${trialAccount ? '?mode=suggestions' : ''}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {subdomain}
                </a>
                <IconButton
                  src="/assets/icons/edit.svg"
                  tooltip="Update link name"
                  onClick={() => onReset(site, files)}
                  className="ms-2"
                />
              </h5>
              {processing ? (
                <div className="d-flex flex-row mt-3 mb-5 align-items-center gap-2 justify-center">
                  <Spinner animation="grow" variant="secondary" size="sm" />
                  <span className="d-inline">Currently processing your site</span>
                </div>
              ) : (
                <div className="d-flex flex-column mt-2 mb-3 align-items-center">
                  <div className="mt-2 d-flex align-items-center flex-row">
                    {files.length > 0 && (
                      <Button className="me-2" variant="outline-primary" onClick={() => onReset(site, files)}>
                        Update
                      </Button>
                    )}
                    <Button
                      onClick={() =>
                        window.open(
                          `https://${subdomain.replace(/\/$/, '')}${trialAccount ? '?mode=suggestions' : ''}`,
                          '_blank'
                        )
                      }
                    >
                      View Site
                    </Button>
                  </div>
                  <Share
                    passwordProtected={passwordProtected}
                    trial={!!!productId}
                    url={`https://${subdomain}`}
                    directFileUrl={getDirectFileLink(`https://${subdomain}`, filename, 'pdf', lastUpdated || created)}
                    onQRClick={onQRCodeClick}
                    onEmbed={() => {
                      dispatch(setEmbedData(true, site))
                    }}
                    type={type}
                    onUpgrade={() => onUpgrade(PLANS.TINY.id)}
                  />
                  <div className="d-flex align-items-center justify-items-center mt-3 gap-1 font-small">
                    <span className="d-inline">Something broken? -</span>
                    <a
                      className="d-inline link"
                      href="https://helpdesk.tiiny.host/en/category/common-issues-wocoo1/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read our help guide
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default SuccessBox
