import React, { useEffect, useState } from 'react'
import { Modal, Button, Nav, Tab, Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { TRANSACTION_FAILED, TRANSACTION_PROCESSING, transactionInit } from '../../Pricing/actions'
import { LoadingBox } from '../LoadingBox/LoadingBox'
import { ErrorBox } from '../ErrorBox/ErrorBox'
import { PERIOD_MONTH, PERIOD_YEAR, PLANS } from '../../constants/plans'
import PaddlePay from './components/PaddlePay'
import { ERROR_CODE_MAP } from '../../stripe.constants'
import { QUARTERLY } from '../../constants/plans/pro-student'
import StripePay from './components/Stripe/StripePay'
import StripePaypal from './components/Stripe/Paypal'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { enrollInEmailList, MAIL_LIST_CHECKOUT_DROP } from '../../services/email-list'
import FeatureList from '../UpgradePayModal/components/FeatureList'
import CurrencyDropdown from 'components/CurrencyDropdown/CurrencyDropDown'
import { availableCurrencies } from 'constants/pricing/currency'
import { abandonedCheckout } from 'utils/sendy'
import { useHistory } from 'react-router-dom'
import CustomModal from 'components/CustomModal/CustomModal'

import styles from './PaymentModal.module.css'

const getErrorMessagePayment = ({ declineCode = '', errorCode, accountAlreadyExistsMessage }) => {
  return (
    accountAlreadyExistsMessage ||
    ERROR_CODE_MAP[declineCode] ||
    `Sorry, unable to process your card. Please try again (${errorCode} ${declineCode})`
  )
}

const getOnClickAction = ({ dispatch, accountAlreadyExistsMessage }) => {
  if (accountAlreadyExistsMessage) return () => (window.location.href = '/login')
  return () => dispatch(transactionInit())
}

const getPriceLabel = (plan, period, discountPercent) => {
  const currency = plan.prices[period]?.currency || '$'
  const value = plan.prices[period]?.value || 0
  const originalPrice = plan.prices[period] && `${currency} ${value}`
  if (discountPercent) {
    const amount = parseInt(value)
    const discount = parseFloat(discountPercent)
    const final = (discount * amount) / 100
    const finalPrice = amount - final

    return (
      <>
        <span style={{ textDecoration: 'line-through' }}>{originalPrice}</span>
        <span className="ps-1">
          {currency}
          {finalPrice.toFixed(2)}
        </span>
      </>
    )
  } else {
    return originalPrice
  }
}

const PaymentModal = ({
  show,
  onHide,
  dispatch,
  declineCode,
  errorCode,
  transactionState,
  email,
  studentData,
  plan,
  periodProp = 'monthly',
  source,
  accountAlreadyExistsMessage,
  accountCreated,
  onCurrencySelected,
  isTrial: isFreeAccount
}) => {
  const [period, setPeriod] = useState(periodProp)
  const isLoading = transactionState === TRANSACTION_PROCESSING
  const isError = transactionState === TRANSACTION_FAILED
  const priceData = plan.prices[period] || {}
  const [errorMessage, setErrorMessage] = useState('')
  const [discountPercent, setDiscountPercent] = useState()
  const history = useHistory()

  useEffect(() => setPeriod(periodProp), [periodProp])

  const handleCouponCodeChange = (e) => {
    setDiscountPercent(e)
  }

  const handleDurationChange = (duration) => {
    setPeriod(duration)
    setErrorMessage('')
  }

  const urlParams = new URLSearchParams(window.location.search)
  const useStripePaypal = urlParams.get('stripe-paypal') === 'true'
  const showCurrencyDropdown = isFreeAccount && plan.id !== PLANS.API.id

  const onClose = () => {
    if (email) {
      enrollInEmailList(email, MAIL_LIST_CHECKOUT_DROP) // Fire & forget
      abandonedCheckout()
    }

    const { pathname } = window.location
    if (pathname === '/manage/subscription/upgrade' || pathname === '/manage/subscription/change') {
      history.push('/manage')
    }

    if (onHide) onHide()
  }

  return (
    <CustomModal
      backdrop="static"
      size="lg"
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.dialog}
      centered
      enforceFocus={false}
      mobileCloseButton={false}
    >
      <Container fluid>
        <Row>
          <Col lg={5} className={`bg-light-grey p-4 ${styles.featureContainer}`}>
            <div className="d-block d-md-none">
              <img onClick={onClose} className="link float-right" src="/delete.png" height="20" />
            </div>
            <h2 className="mb-2 color-purple-2 font-22 mb-lg-2">
              <b>Sign up to {plan.label}</b>
            </h2>
            <FeatureList planId={plan.id} stage={null} list={[]} />
            <div className={`${styles.toc} d-none d-lg-block`}>
              By using our service you accept our{' '}
              <a href="https://tiiny.host/termsofservice.html" target="_blank" rel="noopener noreferrer">
                <u>terms & conditions</u>
              </a>{' '}
              &{' '}
              <a href="https://tiiny.host/privacypolicy.html" target="_blank" rel="noopener noreferrer">
                <u>privacy policy</u>
              </a>
            </div>
          </Col>
          <Col lg={7} className={`p-4 ${styles.paymentContainer}`}>
            <div className="d-none d-md-block">
              <img onClick={onClose} className="link float-right" src="/delete.png" height="20" />
            </div>
            <div className="mt-lg-4 text-center">
              <div className={isLoading || isError ? 'd-none' : 'd-block'}>
                <span className="font-16 grey bg-light-grey mb-4 m-auto p-2">🎖️ 7 day money back guarantee</span>
                <div style={{ display: 'flex' }} className="p-4">
                  {plan.prices.monthly && (
                    <Button
                      className="flex-grow-1"
                      variant={period === 'monthly' ? 'outline-primary' : 'outline-secondary'}
                      onClick={() => handleDurationChange(PERIOD_MONTH)}
                    >
                      <div className="price">
                        <h6>Monthly</h6>
                        <div>
                          {getPriceLabel(plan, PERIOD_MONTH)} <span className="price-period">/ month</span>
                        </div>
                      </div>
                    </Button>
                  )}

                  {plan.prices.yearly && (
                    <Button
                      className="flex-grow-1 ms-2"
                      variant={period === PERIOD_YEAR ? 'outline-primary' : 'outline-secondary'}
                      onClick={() => handleDurationChange(PERIOD_YEAR)}
                    >
                      <div className="price">
                        <h6>Yearly</h6>
                        <div>
                          {getPriceLabel(plan, PERIOD_YEAR)} <span className="price-period">/ year</span>
                        </div>
                        {[PLANS.PRO.id, PLANS.PRO_U.id].includes(plan.id) && (
                          <div className="price-period mt-1">(2 months free)</div>
                        )}
                        {[PLANS.TINY.id].includes(plan.id) && <div className="price-period mt-1">(40% off)</div>}
                      </div>
                    </Button>
                  )}
                </div>
                <div className="flex justify-content-between align-items-baseline">
                  <p>
                    You will be billed{' '}
                    <b>
                      {getPriceLabel(plan, period, discountPercent)} {period.toLowerCase()}
                    </b>
                    .
                  </p>
                  {showCurrencyDropdown && (
                    <CurrencyDropdown
                      selectedCurrency={plan.prices.monthly?.currency}
                      onCurrencySelected={onCurrencySelected}
                      availableCurrencies={availableCurrencies}
                    />
                  )}
                </div>
              </div>
              <>
                <Tab.Container defaultActiveKey="card">
                  <Nav variant="pills" className={`flex-row tabs ${isLoading || isError ? 'd-none' : ''}`}>
                    <Nav.Item>
                      <Nav.Link eventKey="card">
                        <h2 className="fs-normal">
                          <img src="/assets/icons/credit-card.svg" className="me-2 opacity-50" height={15} width={15} />
                          Card
                        </h2>
                      </Nav.Link>
                    </Nav.Item>
                    {/*{useStripePaypal && (
                      <Nav.Item>
                        <Nav.Link eventKey="stripe-paypal">
                          <h2 className="fs-normal">
                            <img src="/assets/icons/paypal.svg" className="me-2 opacity-50" height={20} width={20} />
                            Paypal
                          </h2>
                        </Nav.Link>
                      </Nav.Item>
                    )}*/}
                    {plan.id !== PLANS.API.id && (
                      <Nav.Item>
                        <Nav.Link eventKey="paypal">
                          <h2 className="fs-normal">
                            <img src="/assets/icons/paypal.svg" className="me-2 opacity-50" height={12} width={12} />
                            Paypal
                          </h2>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="card">
                      <StripePay
                        className={isLoading || isError ? 'd-none' : 'd-block mt-3'}
                        dispatch={dispatch}
                        priceData={priceData}
                        email={email}
                        studentData={studentData}
                        source={source}
                        productId={plan.id}
                        period={period}
                        accountCreated={accountCreated}
                        handleCouponCodeChange={handleCouponCodeChange}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                      />
                    </Tab.Pane>

                    {/* Not used */}
                    {/*<Tab.Pane eventKey="stripe-paypal" unmountOnExit={false} mountOnEnter={true}>
                      <StripePaypal
                        className={isLoading || isError ? 'd-none' : 'd-block mt-3'}
                        dispatch={dispatch}
                        priceData={priceData}
                        email={email}
                        studentData={studentData}
                        source={source}
                        productId={plan.id}
                        period={period}
                        accountCreated={accountCreated}
                      />
                    </Tab.Pane>*/}
                    {/* Not used */}

                    {plan.id !== PLANS.API.id && (
                      <Tab.Pane eventKey="paypal" unmountOnExit={false} mountOnEnter={true}>
                        <PaddlePay
                          className={isLoading || isError ? 'd-none' : 'd-block'}
                          dispatch={dispatch}
                          priceId={priceData.id}
                          email={email}
                          studentData={studentData}
                          paddleLink={priceData.paddleLink}
                          paddleId={priceData.paddleId}
                          transactionState={transactionState}
                          source={source}
                          productId={plan.id}
                          period={period}
                          accountCreated={accountCreated}
                        />
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Tab.Container>
              </>
              <LoadingBox className={isLoading ? 'd-block' : 'd-none'} message="Processing" />
              {isError && (
                <ErrorBox
                  className="d-block"
                  message={getErrorMessagePayment({
                    errorCode,
                    declineCode,
                    accountAlreadyExistsMessage,
                    paddleLink: priceData.paddleLink
                  })}
                  onClick={getOnClickAction({ dispatch, accountAlreadyExistsMessage })}
                />
              )}
            </div>
          </Col>
        </Row>

        {/* Only on mobile */}
        <div className="d-lg-none op-6 font-small ps-3 pe-3 pb-3">
          By using our service you accept our{' '}
          <a href="https://tiiny.host/termsofservice.html" target="_blank" rel="noopener noreferrer">
            <u>terms & conditions</u>
          </a>{' '}
          &{' '}
          <a href="https://tiiny.host/privacypolicy.html" target="_blank" rel="noopener noreferrer">
            <u>privacy policy</u>
          </a>
        </div>
      </Container>
    </CustomModal>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })
const mapStateToProps = ({ pricing, manage }) => ({
  accountCreated: manage?.userProfile?.created,
  transactionState: pricing?.transactionState,
  declineCode: pricing?.declineCode,
  errorCode: pricing?.errorCode,
  accountAlreadyExistsMessage: pricing?.accountAlreadyExistsMessage,
  isTrial: !manage?.userProfile?.productId
})

export default connect(mapStateToProps, mapDispatchToProps)(withGoogleReCaptcha(PaymentModal))
