import React from 'react'
import CustomTooltip from '../CustomTooltip'

import styles from './IconButton.module.css'

const IconButton = ({ className = '', disabled, onClick, src, tooltip, label, link }) => {
  const button = (
    <button
      className={`${className} ${styles.container} ${disabled && 'disabled-button-important'}`}
      onClick={onClick}
      disabled={disabled}
    >
      <img height="24" width="24" src={src} alt="tooltip" />
      {label && <span className="ms-2 text-black font-14">{label}</span>}
    </button>
  )

  const component = tooltip ? (
    <CustomTooltip label={tooltip} link={link}>
      {button}
    </CustomTooltip>
  ) : (
    button
  )

  return <>{component}</>
}

export default IconButton
