import React, { useEffect, useState } from 'react'
import { Form, Button, Col, Row, InputGroup, Alert } from 'react-bootstrap'
import { withRouter } from 'react-router'
import * as Yup from 'yup'
import { ErrorMessage, Formik } from 'formik'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { transactionFailed, transactionInit, transactionProcessing } from '../../../../Pricing/actions'
import { createPaymentMethod, createSubscription } from '../../../../services/payment'
import { setShowPaymentModal } from 'actions'
import GoogleApplePay from './GoogleApplePay'
import styles from '../../PaymentModal.module.css'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { PLANS, PLANS_ID } from '../../../../constants/plans'
import { calcUpgradeTime, trackSubscriptionUpgrade, trackSubscriptionUpgradeInfo } from '../../../../utils/analytics'
import { API_ROOT } from '../../../../constants'
import api from 'utils/api'
import * as Sentry from '@sentry/react'
import UPIPay from './UPIPay'
import { getUpgradeReason } from 'utils/general'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      border: 'solid 1px #333',
      color: '#32325d',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

const schema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Email is invalid').required('Email is required')
})

const getRedirectUrl = ({ customerId, isTrialUpgrade, discountPercentage, productId }) => {
  let url = `/pro-welcome?id=${customerId}&skipq=${isTrialUpgrade}`

  if (discountPercentage) {
    url += `&percentOff=${discountPercentage}`
  }

  if (productId === PLANS.API.id) {
    url += `&api-welcome=true`
  }

  return url
}

const PaymentModal = ({
  className,
  dispatch,
  history,
  priceData,
  email,
  studentData = {},
  source,
  productId,
  period,
  accountCreated,
  googleReCaptchaProps,
  handleCouponCodeChange,
  errorMessage,
  setErrorMessage
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const isTrialUpgrade = window.location.pathname.includes('manage')

  const { stripePriceId: priceId } = priceData
  const discountCode = localStorage.getItem('discount-code')

  const [isLoading, setIsLoading] = useState(false)
  const [isCouponValid, setIsCouponValid] = useState(false)
  const [showDiscount, setShowDiscount] = useState(false)
  const [couponCode, setCouponCode] = useState(null)
  const [couponPercent, setCouponPercent] = useState(null)
  const [isCardValid, setIsCardValid] = useState(false)
  const [cardError, setCardError] = useState(null)

  useEffect(() => {
    // reset coupon
    handleCouponCodeChange(undefined)
    setIsCouponValid(false)
    setCouponPercent(null)
  }, [period])

  useEffect(() => {
    if (discountCode) {
      setCouponCode(discountCode)
      verifyCoupon(discountCode)
    }
  }, [discountCode, period])

  const verifyCoupon = async (couponCode) => {
    const headers = {}
    const body = {
      couponId: couponCode
    }
    if (!couponCode) {
      setErrorMessage('Please enter a coupon code')
      return
    }
    try {
      setIsLoading(true)

      const response = await api.post(`${API_ROOT}/pro/verify-coupon`, body, headers)
      if (response.data.error) {
        throw response.data
      }
      const newCoupon = response.data
      setIsCouponValid(true)
      setErrorMessage(undefined)
      setCouponPercent(parseFloat(newCoupon.data.percent_off))
      handleCouponCodeChange(newCoupon.data.percent_off)
      alert('Discount applied')

      if (localStorage) {
        localStorage.removeItem('discount-code')
      }

      return response.data
    } catch (error) {
      setErrorMessage('Invalid coupon code.')
    } finally {
      setIsLoading(false)
    }
  }

  const onSuccess = (customerId, transactionId, isTrialUpgrade, upgradeReason) => {
    dispatch(transactionInit())
    dispatch(setShowPaymentModal({ showPaymentModal: false, upgradePlanId: null }))
    trackSubscriptionUpgrade(
      email,
      PLANS_ID[productId]?.label,
      PLANS_ID[productId]?.prices[period]?.value,
      period,
      transactionId,
      source
    )
    trackSubscriptionUpgradeInfo(email, calcUpgradeTime(accountCreated), upgradeReason)

    isCouponValid
      ? history.push(getRedirectUrl({ customerId, isTrialUpgrade, couponPercent, productId }))
      : history.push(getRedirectUrl({ customerId, isTrialUpgrade, productId }))
  }

  const handle3DSecure = (paymentIntent, paymentMethodId, customerId, subscriptionParams) => {
    stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId
      })
      .then((result) => {
        if (result.error) {
          dispatch(transactionFailed({ errorCode: '3D_SECURE_FAILED' }))
          throw new Error('3D_SECURE_FAILED')
        } else if (result.paymentIntent.status === 'succeeded') {
          onSuccess(customerId, result?.paymentIntent?.id, isTrialUpgrade, subscriptionParams?.upgradeReason)
        }
      })
      .catch(() => {
        dispatch(transactionFailed({ errorCode: '3D_SECURE_FAILED' }))
        throw new Error('3D_SECURE_FAILED')
      })
  }

  const processSubscription = (subscriptionParams, paymentMethodId, recaptchaValue) => {
    const headers = {
      'x-recaptcha': recaptchaValue
    }
    createSubscription({
      ...subscriptionParams,
      paymentMethodId,
      couponId: isCouponValid ? couponCode : null,
      headers
    })
      .then(async ({ data }) => {
        //success
        if (data?.subscriptionStatus === 'active') {
          // affiliate tracking
          if (window.fpr) {
            window.fpr('referral', { email: subscriptionParams.email.toLowerCase() })
          }
          onSuccess(data?.customerId, data?.paymentIntent?.id, isTrialUpgrade, subscriptionParams?.upgradeReason)
        } else if (data?.paymentStatus === 'requires_action') {
          //3D Secure
          await handle3DSecure(data?.paymentIntent, paymentMethodId, data?.customerId, subscriptionParams)
        } else {
          dispatch(transactionFailed({ errorCode: 'CREATE_SUBSCRIPTION_FAILED' }))
          throw Error('CREATE_SUBSCRIPTION_FAILED')
        }
      })
      .catch((err) => {
        const error = err?.response?.data || err
        const declineCode = error?.declineCode || error?.message
        const errorCode = error?.errorCode || error?.message

        let accountAlreadyExistsMessage

        if (error?.code === 'DUPLICATE_ACCOUNT_REQUEST') {
          accountAlreadyExistsMessage = error?.message
        }

        dispatch(transactionFailed({ declineCode, errorCode, accountAlreadyExistsMessage }))
      })
  }

  const processCardTransaction = async (subscriptionParams, cardElement, recaptchaValue) => {
    const { error, paymentMethod } = await createPaymentMethod(stripe, cardElement)

    if (error) {
      dispatch(transactionFailed({ errorCode: `CPM_FAILED - ${error.type}` }))
      Sentry.captureException(error, {
        tags: {
          section: 'payment-process-card-error'
        }
      })
    } else {
      processSubscription(subscriptionParams, paymentMethod.id, recaptchaValue)
    }
  }

  const processAppleGooglePayTransaction = async (subscriptionParams, paymentMethodId) => {
    dispatch(transactionProcessing())
    const recaptchaValue = await googleReCaptchaProps.executeRecaptcha('payment')
    processSubscription(subscriptionParams, paymentMethodId, recaptchaValue)
  }

  const onSubmit = async (formData) => {
    if (!isCardValid) return

    dispatch(transactionProcessing())
    const recaptchaValue = await googleReCaptchaProps.executeRecaptcha('payment')
    const subscriptionParams = {
      ...formData,
      name: `${formData.firstName} ${formData.lastName}`,
      priceId,
      productId,
      paymentGateway: 'STRIPE'
    }

    if (isCouponValid) {
      subscriptionParams.couponCode = couponCode
    }

    const reason = getUpgradeReason()

    subscriptionParams.upgradeReason = formData.upgradeReason || reason || "N/A"
    

    await processCardTransaction(subscriptionParams, elements.getElement(CardElement), recaptchaValue)
  }

  const handleCardChange = (event) => {
    setIsCardValid(event.complete)
    if (event.error) {
      setCardError(event.error.message)
    } else {
      setCardError(null)
    }
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        email: email || studentData.email
      }}
    >
      {({ handleSubmit, handleChange, values }) => (
        <form className={className} onSubmit={handleSubmit}>
          <UPIPay payProId={priceData.payProId} email={email} />
          <GoogleApplePay
            priceData={priceData}
            productId={productId}
            processTransaction={processAppleGooglePayTransaction}
          />
          <Row>
            <Col>
              <Form.Control
                name="firstName"
                type="text"
                placeholder="First name"
                value={values.firstName}
                onChange={handleChange}
              />
              <ErrorMessage name="firstName" component="div" className="validation-error" />
            </Col>
            <Col>
              <Form.Control
                name="lastName"
                type="text"
                placeholder="Last name"
                value={values.lastName}
                onChange={handleChange}
              />
              <ErrorMessage name="lastName" component="div" className="validation-error" />
            </Col>
          </Row>
          {!email && (
            <Form.Control
              className="mt-2"
              name="email"
              type="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
            />
          )}
          <ErrorMessage name="email" component="div" className="validation-error" />
          <CardElement className={styles.StripeElement} options={CARD_ELEMENT_OPTIONS} onChange={handleCardChange} />
          <div className="p-1" style={{ minHeight: 30 }}>
            <div className="opacity-50 font-14 float-start">
              Secured with{' '}
              <a href="https://stripe.com" target="_blank" rel="noreferrer">
                <img src="/assets/icons/stripe.svg" height="30" />
              </a>
            </div>
            {showDiscount ? (
              <>
                <InputGroup className="mt-2">
                  <Form.Control
                    className=""
                    placeholder="Enter coupon code"
                    aria-label="discount"
                    name="couponCode"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    onClick={() => setErrorMessage('')}
                    disabled={isLoading}
                  />

                  <Button size="sm" onClick={() => verifyCoupon(couponCode)} disabled={isLoading}>
                    {isLoading ? 'Applying...' : 'Apply'}
                  </Button>
                </InputGroup>
                {errorMessage && <span className="text-danger">{errorMessage}</span>}
              </>
            ) : (
              <div className="font-small float-end mt-1">
                {!discountCode && (
                  <u
                    className={styles.apply}
                    onClick={() => {
                      setShowDiscount(true)
                    }}
                  >
                    Apply discount code
                  </u>
                )}
              </div>
            )}
          </div>
          {cardError && (
            <Alert variant="danger" className="mt-2">
              {cardError}
            </Alert>
          )}
          <Form.Control
            name="upgradeReason"
            className="mt-2"
            as="select"
            value={values.upgradeReason}
            onChange={handleChange}
          >
            <option value="">Reason for upgrade...</option>
            <option value="custom-domain">Link a custom domain</option>
            <option value="more-links">Upload more projects</option>
            <option value="file-size">Upload a larger file</option>
            <option value="analytics">Analytics</option>
            <option value="editing">Editing functionalities</option>
            <option value="no-ads">Remove Tiiny Host branding</option>
            <option value="other">Other</option>
          </Form.Control>
          <div>
            <Button className="w-100 mt-3 tr-stripe-pay" type="submit" disabled={!stripe}>
              Pay
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default withRouter(withGoogleReCaptcha(PaymentModal))
