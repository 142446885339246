import React, { useState } from 'react'
import InitialStage from './components/InitialStage'
import ConfirmationStage from './components/ConfirmationStage'
import { ErrorBox } from '../ErrorBox/ErrorBox'
import ResearchCall from './components/ResearchCall'
import PauseSubscription from './components/PauseSubscription'

const STAGE_STATS = 'STATS'
const STAGE_PAUSE = 'PAUSE'
const STAGE_RESEARCH = 'RESEARCH_CALL'

const Cancel = ({ onHide, sites, onUpgrade, visitorCount, currentPlanId, isStripeCustomer, nextBillingDate }) => {
  const [hasError, setHasError] = useState(false)
  const [stage, setStage] = useState(isStripeCustomer ? STAGE_PAUSE : STAGE_STATS)

  if (hasError) {
    return <ErrorBox onClick={() => setHasError(false)} />
  }

  console.log(nextBillingDate)

  if (stage === STAGE_PAUSE) {
    // Only for Stripe customers
    return <PauseSubscription
      onHide={onHide}
      onCancel={() => setStage(STAGE_STATS)}
      nextBillingDate={nextBillingDate}
    />
  } else if (stage === STAGE_RESEARCH) {
    return <ResearchCall onHide={onHide} />
  } else if (stage === STAGE_STATS) {
    return (
      <InitialStage
        sites={sites}
        onHide={onHide}
        visitorCount={visitorCount}
        currentPlanId={currentPlanId}
        onContinueToCancellation={() => setStage('cancellation')}
      />
    )
  }

  return (
    <ConfirmationStage
      sites={sites}
      onUpgrade={onUpgrade}
      onBack={() => setStage(STAGE_STATS)}
      onHide={onHide}
      setHasError={setHasError}
      onBookUserResearchCall={() => setStage(STAGE_RESEARCH)}
    />
  )
}

export default Cancel
