import React from 'react'
import PlanCard from './components/PlanCard'
import { PLANS } from '../../constants/plans'
import PricingTable from 'components/PricingTable/PricingTable'
import { ALL_FEATURES, PRICING_FEATURES } from '../../constants/plans'

import styles from './PricingGrid.module.css'
import { LogoShowcase } from '../../Landing/Home/Home'

const PricingGrid = ({ className, period, prices, onSubPlanClick, onFreeClick, hideFreeCard, hideTable }) => {
  return (
    <>
      <div className={`${className} ${styles.container} mb-5`}>
        {!hideFreeCard && (
          <PlanCard
            title="Free"
            plan={PLANS.FREE}
            planPrice={prices.freePlan}
            subtitle="Try us out to quickly share a project"
            features={PRICING_FEATURES[PLANS.FREE.id]}
            period={period}
            ctaTheme="black"
            onSubPlanClick={onFreeClick}
          />
        )}
        <PlanCard
          plan={PLANS.TINY}
          planPrice={prices.tinyPlan}
          title="Tiny"
          subtitle="Perfect for a single, professional link"
          features={PRICING_FEATURES[PLANS.TINY.id]}
          period={period}
          ctaTheme="blue"
          onSubPlanClick={onSubPlanClick}
          hideCompareCTA={hideTable}
        />
        <PlanCard
          plan={PLANS.PRO}
          planPrice={prices.soloPlan}
          title="Solo"
          subtitle="Great for individuals & small projects"
          features={PRICING_FEATURES[PLANS.PRO.id]}
          period={period}
          ctaTheme="purple"
          onSubPlanClick={onSubPlanClick}
          hideCompareCTA={hideTable}
          popular
        />
        <PlanCard
          plan={PLANS.PRO_U}
          planPrice={prices.proPlan}
          title="Pro"
          subtitle="For freelancers, agencies & companies"
          features={PRICING_FEATURES[PLANS.PRO_U.id]}
          period={period}
          ctaTheme="warning"
          onSubPlanClick={onSubPlanClick}
          hideCompareCTA={hideTable}
        />
      </div>
      {!hideTable && (
        <>
          <LogoShowcase title="Do more with your links" className="mt-2 mb-5" />
          <PricingTable plans={PLANS} prices={prices} period={period} featureList={ALL_FEATURES} />
        </>
      )}
    </>
  )
}

export default PricingGrid
