import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { mbValue } from '../../utils/general'

import './ProgressBar.css'

export const getSimulationDuration = (fileSize) => {
  if (fileSize < mbValue(100)) {
    return 300
  } else if (fileSize >= mbValue(100) && fileSize < mbValue(500)) {
    return 1000
  } else if (fileSize >= mbValue(500)) {
    return 1500
  }
}

let progressInterval = null

export const SimulatedProgressBar = ({ start, duration = 300 }) => {
  const [progress, setProgress] = useState(start)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => prev + 1)
    }, duration)
    return () => clearInterval(interval)
  }, [duration])

  useEffect(() => {
    if (progress >= 100) {
      clearInterval(progressInterval)
    }
  }, [progress])

  return <ProgressBarLoader now={progress} />
}

const ProgressBarLoader = ({ className = '', progressBarMessage = 'Working magic...', now }) => (
  <div className={`progress-bar-loader ${className}`}>
    <div className="mb-3">{progressBarMessage}</div>
    <ProgressBar animated now={now} label={`${now}%`} />
  </div>
)

export default ProgressBarLoader
