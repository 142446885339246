import React, { useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import api from 'utils/api'
import { fetchUserData } from '../../Manage/actions'
import { useDispatch, useSelector } from 'react-redux'
import { API_ROOT } from '../../constants'
import { showUpgradeCardModal, setShowPaymentModal } from 'actions'
import { PLANS, ARCHIVE_LIMIT_FREE_PLAN } from 'constants/plans'
import CustomModal from 'components/CustomModal/CustomModal'
import { ACTION_UPDATE } from 'components/CreateUpdateSiteModal/interface'


const ReplaceUpgradeModal = ({ show, message, domain, onClose, title, onSiteAction }) => {
  const dispatch = useDispatch()
  const [showReplaceInstructions, setShowReplaceInstructions] = useState(false)

  const handleAction = async (action) => {
    if (action === 'UPGRADE') {
      dispatch(
       setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.PRO.id })
      )
      onClose()
      return
    }
    
    if (action === 'REPLACE') {
      setShowReplaceInstructions(true)
      return
    }
  }

  const handleReplaceInstructionsClose = () => {
    setShowReplaceInstructions(false)
    onClose()
  }

  return (
    <>
      <CustomModal
        aria-labelledby="contained-modal-title-vcenter"
        show={show && !showReplaceInstructions}
        onHide={onClose}
        animation={false}
        centered
        enforceFocus={false}
      >
        <Modal.Header className="p-3 pb-2 border-0 justify-start">
          <Modal.Title className="bold color-purple-light text-center">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-24px font-18" dangerouslySetInnerHTML={{ __html: message }} />
        <Modal.Footer className="border-0">
          <Button variant="outline-primary" onClick={() => handleAction('REPLACE')} >
            Replace
          </Button>
          <Button variant="primary" onClick={() => handleAction('UPGRADE')} >
            Upgrade
          </Button>
        </Modal.Footer>
      </CustomModal>

      <CustomModal
        aria-labelledby="replace-instructions-modal"
        show={showReplaceInstructions}
        onHide={handleReplaceInstructionsClose}
        animation={false}
        centered
        enforceFocus={false}
      >
        <Modal.Header className="p-3 pb-2 border-0 justify-start">
          <Modal.Title className="bold color-purple-light text-center">How to replace your current project</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-24px font-18">
          Click on the Update icon in your project list and simply update the files in your current project.
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="primary" onClick={handleReplaceInstructionsClose}>
            Close
          </Button>
        </Modal.Footer>
      </CustomModal>
    </>
  )
}

export default ReplaceUpgradeModal