import React from 'react'
import SignUpPage from '../Login/SignUpModal'

import styles from './SignUp.module.css'

const SignUp = ({}) => (
  <div>
    <img alt="Tiiny Host" src="/assets/logo.png" className={styles.logo} />

    <div className={styles.signUpBox}>
      <SignUpPage isLoginPage />
    </div>
  </div>
)

export default SignUp
