import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PaymentModal from '../PaymentModal'
import {
  getCurrencyAndPrice,
  getSelectedPlanAndPrices,
  getCurrencySymbolByCountryCode
} from 'constants/pricing/currency'
import { PLANS, PLANS_ID } from '../../constants/plans'
import UpgradePayModal from 'components/UpgradePayModal/UpgradePayModal'
import { SOURCE_LOGGED_IN, SOURCE_UNKNOWN } from '../../constants'

const PlanPaymentModal = ({
  userProfile,
  planId,
  show,
  onHide,
  history,
  studentData,
  periodProp,
  source = SOURCE_UNKNOWN,
  email
}) => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const freeAccount = !userProfile?.productId
  const currency = planId === PLANS.API.id ? '$' : userProfile?.currency

  useEffect(() => {
    if (planId) {
      const initialPlan = PLANS_ID[planId]
      const plan = getSelectedPlanAndPrices(initialPlan, getCurrencyAndPrice(currency))
      setSelectedPlan(plan)
    }
  }, [planId, currency, periodProp])

  const onCurrencySelected = (currency) => {
    const prices = getCurrencyAndPrice(currency)
    const newPlan = getSelectedPlanAndPrices(selectedPlan, prices)
    setSelectedPlan(newPlan)
  }

  const onClose = () => {
    onHide()
    setSelectedPlan(null)
  }

  if (!selectedPlan) {
    return null
  }

  return (
    <>
      {freeAccount ? (
        <PaymentModal
          show={show}
          onHide={onClose}
          plan={selectedPlan}
          studentData={studentData}
          email={userProfile?.email || email}
          source={userProfile?.email ? SOURCE_LOGGED_IN : source}
          onCurrencySelected={onCurrencySelected}
          periodProp={periodProp}
        />
      ) : (
        <UpgradePayModal
          show={show}
          onHide={onClose}
          periodProp="monthly"
          currentPlanId={userProfile?.productId}
          email={userProfile?.email}
          interval={userProfile?.interval}
          currency={getCurrencySymbolByCountryCode(currency)}
          history={history}
          defaultPlan={planId}
          userProfile={userProfile}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ manage }) => ({
  userProfile: manage.userProfile
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(PlanPaymentModal)
