import React, { useState, useRef } from 'react'
import styles from './DomainCell.module.css'
import CustomTooltip from '../../../CustomTooltip'
import { Overlay, Tooltip } from 'react-bootstrap'
import { copyToClipboard } from '../../../../utils/general'

const DomainCell = ({ label, href, site, handlePreviewClick, disabled, trialAccount }) => {
  const [showCopyTooltip, setShowCopyTooltip] = useState(false)
  const copyBtnRef = useRef(null)

  const onDomainClick = (e) => {
    if (disabled) return undefined

    e.preventDefault()
    if (site?.linkPreview) {
      handlePreviewClick(site)
    } else {
      const link = trialAccount ? `${href.replace(/\/$/, '')}${trialAccount ? '?mode=suggestions' : ''}` : href
      window.open(link, '_blank')
    }
  }

  const onCopy = (link) => {
    copyToClipboard(link)
    setShowCopyTooltip(true)

    setTimeout(() => {
      setShowCopyTooltip(false)
    }, 1500)
  }

  const updatedLabel = !site?.linkPreview ? label : 'Click to preview'

  return (
    <div className={styles.container}>
      <a
        onClick={onDomainClick}
        className={`domain-cell ${styles.link} ${disabled ? styles.disabled : ''}`}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="DomainCell-a-domain-cell"
      >
        <div className={styles.text}>{updatedLabel}</div>
      </a>
      <div ref={copyBtnRef}>
        {!site?.linkPreview && (
          <CustomTooltip label="Copy link">
            <button
              className={`${styles.copyLinkBtn} copy-domain-btn`}
              onClick={() => onCopy(href)}
              disabled={disabled}
            >
              <img className="op-6" height="16" src="assets/icons/copy-link.svg" alt="tooltip" />
            </button>
          </CustomTooltip>
        )}

        {/* Custom overlay for the "Link copied!" message */}
        <Overlay target={copyBtnRef.current} show={showCopyTooltip} placement="bottom">
          {({ placement, ...props }) => (
            <Tooltip id="copy-tooltip" {...props}>
              <div>Link copied!</div>
            </Tooltip>
          )}
        </Overlay>
      </div>
    </div>
  )
}

export default DomainCell
