import React, { useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { pauseSubscription } from '../../../services/manage'

const PauseSubscription = ({ onCancel, onHide, nextBillingDate }) => {
  const location = useLocation()
  const [pauseDuration, setPauseDuration] = useState(1)
  const [isProcessing, setIsProcessing] = useState(false)

  const onPause = async () => {
    setIsProcessing(true)

    try {
      await pauseSubscription({ pauseDuration })

      const searchParams = new URLSearchParams(location.search)
      searchParams.set('pause-success', 'true')
      location.search = searchParams.toString()

      setIsProcessing(false)
      onHide()
      alert('Your subscription is set to pause')
    } catch {
      setIsProcessing(false)
      alert('Error whilst trying to pause your subscription')
    }
  }

  const onClickCancel = () => {
    setIsProcessing(true)
    onCancel()
  }

  const calculateResumeDate = () => {
    if (!nextBillingDate) return null

    const pauseDate = new Date(parseInt(nextBillingDate) * 1000)
    const resumeDate = new Date(pauseDate)
    resumeDate.setMonth(resumeDate.getMonth() + parseInt(pauseDuration))

    return {
      pauseDate: pauseDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
      resumeDate: resumeDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    }
  }

  const dates = calculateResumeDate()

  return (
    <div>
      <h2 className="mb-2 bold color-purple-2 font-22">Pause Subscription?</h2>
      <span className="dark-grey font-18 bold">Keep your work</span>
      <div className="mt-3 grey-background p-4 rounded">
        <h3 className="font-18 bold dark-grey-2 mb-2">How it works</h3>
        <div className="grey mb-2 bold">On your subscription pause date:</div>
        <div className="flex gap-2">
          <img alt="one" src="/assets/icons/one.svg" height={20} width={20} className="mt-1 opacity-25" />
          <div className="grey">Your projects are automatically saved & archived.</div>
        </div>
        <div>
          <div className="grey mt-2 flex gap-2">
            <img alt="two" src="/assets/icons/two.svg" height={20} width={20} className="mt-1 opacity-25" />
            Your subscription resumes after pause period & you'll be notified via email.
          </div>
        </div>
      </div>
      <Form.Group controlId="cancel-reason" className="mt-3">
        <Form.Control as="select" value={pauseDuration} onChange={(e) => setPauseDuration(e.target.value)}>
          <option key="1-month" value={1}>
            Pause for 1 month
          </option>
          <option key="2-month" value={2}>
            Pause for 2 months
          </option>
          <option key="3-month" value={3}>
            Pause for 3 months
          </option>
        </Form.Control>
      </Form.Group>

      {nextBillingDate && dates && (
        <div className="mt-3 dark-grey font-14 ms-1 me-1 mb-5">
          Subscription will pause on <b>{dates.pauseDate}</b> & resume on <b>{dates.resumeDate}</b>
        </div>
      )}

      <div className="mt-3">
        {isProcessing && <Spinner animation="border" className="float-end" />}
        {!isProcessing && (
          <>
            <Button className="float-end" variant="danger" onClick={onClickCancel}>
              Cancel Subscription
            </Button>
            <Button className="float-end me-2" variant="primary" onClick={onPause}>
              Pause Subscription
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default PauseSubscription
