import PRO_ULTIMATE_PLAN from './pro'
import PRO_ULTIMATE_PLAN_2 from './depricated/pro-u-2'
import PRO_ULTIMATE_PLAN_3 from './depricated/pro-u-3'
import PRO_STUDENT from './pro-student'
import TINY_PLAN from './tiny'
import SOLO_PLAN from './solo'
import API_PLAN from './api'

export const PERIOD_MONTH = 'monthly'
export const PERIOD_YEAR = 'yearly'
const convertToBytes = (size) => 1024 * 1024 * size

export const FREE_PLAN = {
  prices: {
    yearly: { value: 0 },
    monthly: { value: 0 }
  },
  label: 'Free',
  projectLimit: 1,
  fileSizeLimit: 3,
  visitorLimit: 5000,
  bandwidthLimit: 5
}

export const PRO_MAX_FILE_SIZES = {
  [TINY_PLAN.id]: TINY_PLAN.fileSizeLimit,
  [PRO_ULTIMATE_PLAN.id]: PRO_ULTIMATE_PLAN.fileSizeLimit,
  [PRO_ULTIMATE_PLAN_2.id]: PRO_ULTIMATE_PLAN_2.fileSizeLimit,
  [PRO_ULTIMATE_PLAN_3.id]: PRO_ULTIMATE_PLAN_3.fileSizeLimit,
  [PRO_STUDENT.id]: PRO_STUDENT.fileSizeLimit,
  [SOLO_PLAN.id]: SOLO_PLAN.fileSizeLimit
}

export const PRO_CUSTOM_DOMAIN_LIMIT = {
  [TINY_PLAN.id]: TINY_PLAN.customDomainsLimit,
  [PRO_ULTIMATE_PLAN.id]: PRO_ULTIMATE_PLAN.customDomainsLimit,
  [PRO_ULTIMATE_PLAN_2.id]: PRO_ULTIMATE_PLAN_2.customDomainsLimit,
  [PRO_ULTIMATE_PLAN_3.id]: PRO_ULTIMATE_PLAN_3.customDomainsLimit,
  [PRO_STUDENT.id]: PRO_STUDENT.customDomainsLimit,
  [SOLO_PLAN.id]: SOLO_PLAN.customDomainsLimit
}

export const PLANS = {
  FREE: FREE_PLAN,
  TINY: TINY_PLAN,
  PRO: SOLO_PLAN, // Solo
  PRO_U: PRO_ULTIMATE_PLAN, // Pro
  PRO_STUDENT: PRO_STUDENT,
  API: API_PLAN
}

export const PLANS_ID = {
  [TINY_PLAN.id]: TINY_PLAN,
  [SOLO_PLAN.id]: SOLO_PLAN,
  [PRO_ULTIMATE_PLAN.id]: PRO_ULTIMATE_PLAN,
  [PRO_STUDENT.id]: PRO_STUDENT,
  [API_PLAN.id]: API_PLAN
}

export const labelToPlanMap = {
  Solo: 'soloPlan',
  Tiny: 'tinyPlan',
  Pro: 'proPlan',
  API: 'apiPlan',
  Free: 'freePlan'
}

export const PLAN_THRESHOLDS = {
  FREE: {
    projectLimit: FREE_PLAN.projectLimit,
    fileSizeLimit: convertToBytes(FREE_PLAN.fileSizeLimit),
    visitorLimit: FREE_PLAN.visitorLimit,
    bandwidthLimit: FREE_PLAN.bandwidthLimit
  },
  TINY: {
    projectLimit: TINY_PLAN.projectLimit,
    fileSizeLimit: convertToBytes(TINY_PLAN.fileSizeLimit),
    visitorLimit: TINY_PLAN.visitorLimit,
    bandwidthLimit: TINY_PLAN.bandwidthLimit
  },
  SOLO: {
    projectLimit: SOLO_PLAN.projectLimit,
    fileSizeLimit: convertToBytes(SOLO_PLAN.fileSizeLimit),
    visitorLimit: SOLO_PLAN.visitorLimit,
    bandwidthLimit: SOLO_PLAN.bandwidthLimit
  },
  PRO: {
    projectLimit: PRO_ULTIMATE_PLAN.projectLimit,
    fileSizeLimit: convertToBytes(PRO_ULTIMATE_PLAN.fileSizeLimit),
    visitorLimit: PRO_ULTIMATE_PLAN.visitorLimit,
    bandwidthLimit: PRO_ULTIMATE_PLAN.bandwidthLimit
  },
  API: {
    projectLimit: API_PLAN.projectLimit,
    fileSizeLimit: convertToBytes(API_PLAN.fileSizeLimit),
    visitorLimit: API_PLAN.visitorLimit,
    bandwidthLimit: API_PLAN.bandwidthLimit
  }
}

export const ALL_FEATURES = [
  {
    label: 'Remove Tiiny Host banner',
    tooltip: 'No Tiiny Host branding on your content',
    enabled: [PLANS.TINY.id, PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Alternate domains',
    tooltip: 'Upload to our exclusive & more reliable .tiiny.co domain',
    enabled: [PLANS.TINY.id, PLANS.PRO.id, PLANS.PRO_U.id]
  },
  {
    label: 'QR Codes',
    tooltip: 'Autogenerated QR codes to download & share',
    enabled: [PLANS.TINY.id, PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Custom domains',
    tooltip: 'Use your website name to publish your content, custom SSL included',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Built-in analytics',
    tooltip: "Automatically track the number of visitors and where they're coming from",
    enabled: [PLANS.TINY.id, PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Edit mode',
    tooltip: 'Easily edit websites, text in PDFs, HTML, CSS & JavaScript files online using our built-in editors',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Password protection',
    tooltip: 'Restrict who can view your content by adding a password',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Capture emails',
    tooltip: 'Capture potential leads by requiring visitors to enter their email address',
    enabled: [PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Disable PDF download/printing',
    tooltip: 'Prevent visitors from downloading or printing your PDF',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id]
  },
  {
    label: 'Custom 404 pages',
    tooltip: 'Configure your own 404 error pages when content not found',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id]
  },
  {
    label: 'Integrations',
    tooltip: 'e.g. Zapier',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id]
  },
  {
    label: 'Feedback mode',
    tooltip: 'Allow visitors to comment on your work',
    enabled: [PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Add team members',
    tooltip: 'Share account with up to two more users (upgradeable)',
    enabled: [PLANS.PRO_U.id],
    featured: true
  }
]

export const PRICING_FEATURES = {
  [PLANS.FREE.id]: [],
  [PLANS.TINY.id]: [
    {
      label: 'Remove Tiiny Host banner',
      tooltip: 'No Tiiny Host branding on your content',
      icon: 'swatch.svg',
      checkicon: 'check-pricing-tiny.svg'
    },
    {
      label: 'QR Codes',
      tooltip: 'Generate unique QR codes for your content',
      icon: 'qr-code-blue.svg',
      checkicon: 'check-pricing-tiny.svg'
    },
    {
      label: 'Built-in analytics',
      tooltip: 'Track engagement and performance',
      icon: 'chart-bar-blue.svg',
      checkicon: 'check-pricing-tiny.svg'
    }
  ],
  [PLANS.PRO.id]: [
    {
      label: 'Everything in Tiny Plan',
      tooltip: 'All features of Tiny plan',
      icon: 'plus-circle-solo.svg',
      checkicon: 'check-pricing-solo.svg'
    },
    {
      label: 'Custom domains',
      tooltip: 'Use your own domain for hosting',
      icon: 'domain-solo.svg',
      checkicon: 'check-pricing-solo.svg'
    },
    {
      label: 'Edit mode',
      tooltip: 'Edit content on the fly',
      icon: 'pencil-square.svg',
      checkicon: 'check-pricing-solo.svg'
    },
    {
      label: 'Password protection',
      tooltip: 'Secure your content with a password',
      icon: 'lock-closed.svg',
      checkicon: 'check-pricing-solo.svg'
    }
  ],
  [PLANS.PRO_U.id]: [
    {
      label: 'Everything in Solo Plan',
      tooltip: 'All features of solo plan',
      icon: 'plus-circle-pro.svg',
      checkicon: 'check-pricing-pro.svg'
    },
    {
      label: 'Feedback mode',
      tooltip: 'Allow visitors to comment on your work',
      icon: 'comment-outline-yellow.svg',
      checkicon: 'check-pricing-pro.svg'
    },
    {
      label: 'Capture emails',
      tooltip: 'Collect email addresses from visitors',
      icon: 'envelope-pro.svg',
      checkicon: 'check-pricing-pro.svg'
    },
    {
      label: 'Add team members',
      tooltip: 'Invite others to collaborate',
      icon: 'users-pro.svg',
      checkicon: 'check-pricing-pro.svg'
    }
  ]
}

export const ARCHIVE_LIMIT_FREE_PLAN = 3
