import React, { useState, useRef } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import styles from './CustomToolTip.module.css'

const CustomTooltip = ({
  children,
  label,
  link,
  placement = 'bottom',
  hoverDelay = 300, // Delay before hiding tooltip
  isClickable = false // New flag for clickable content
}) => {
  const [show, setShow] = useState(false)
  const target = useRef(null)
  const timeoutRef = useRef(null)

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setShow(true)
  }

  const handleMouseLeave = () => {
    if (isClickable) {
      timeoutRef.current = setTimeout(() => {
        setShow(false)
      }, hoverDelay)
    } else {
      setShow(false)
    }
  }

  return (
    <div className="d-inline" ref={target} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span onClick={() => setShow(!show)} className={styles['underline-on-hover']}>
        {children}
      </span>
      <Overlay target={target.current} show={show} placement={placement}>
        {({ placement, show: _show, ...props }) => (
          <Tooltip
            id="button-tooltip"
            show={show}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...props}
          >
            <div className={styles.tooltip}>
              <div dangerouslySetInnerHTML={{ __html: label }} />
              {link && (
                <a
                  href={link}
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  Learn more
                </a>
              )}
            </div>
          </Tooltip>
        )}
      </Overlay>
    </div>
  )
}

export default CustomTooltip
