import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Spinner } from 'react-bootstrap'
import { PERIOD_MONTH, PERIOD_YEAR, PLANS, PLANS_ID, ALL_FEATURES } from '../../../constants/plans'
import { CURRENT_PLAN, DOWNGRADE, UPGRADE } from '../UpgradePayModal'
import PlanSelectionDropdown from './PlanSelectionDropdown'
import FeatureList from './FeatureList'
import DowngradeChecklist from './DowngradeChecklist'
import { formatFileSize } from 'utils/general'

const downGradeFeatureList = (currentPlan, newPlan) => {
  const currentPlanFeatures = ALL_FEATURES.filter((feature) => feature.enabled.includes(currentPlan?.id))
  const newPlanFeatures = ALL_FEATURES.filter((feature) => feature.enabled.includes(newPlan?.id))
  return currentPlanFeatures.filter(
    (currentFeature) => !newPlanFeatures.some((newFeature) => newFeature.label === currentFeature.label)
  )
}

const getPriceLabel = (plan, period) =>
  plan.prices[period] && `${plan.prices[period].currency || '$'} ${plan.prices[period].value}`

const checkPlanChange = (currentPlan, newPlan) => {
  const planOrder = {
    [PLANS.TINY.id]: 1,
    [PLANS.PRO.id]: 2,
    [PLANS.PRO_U.id]: 3
  }

  if (planOrder[currentPlan] < planOrder[newPlan]) {
    return UPGRADE
  } else if (planOrder[currentPlan] > planOrder[newPlan]) {
    return DOWNGRADE
  } else {
    return CURRENT_PLAN
  }
}

const PlanView = ({
  isLoading,
  currentPlanId,
  defaultPlan,
  periodProp,
  onConfirm,
  onDowngradeSubscription,
  interval,
  onPlanChange,
  selectedPlan,
  userProfile,
  liveSites,
  customDomains
}) => {
  const [period, setPeriod] = useState(periodProp)
  const [planStage, setPlanStage] = useState(CURRENT_PLAN)
  const [downGradeList, setDownGradeList] = useState([])
  const [isDowngradeCheckList, setIsDowngradeCheckList] = useState(false)
  const [downgradeIssues, setDowngradeIssues] = useState({})

  const activeInterval = interval === 'year' ? PERIOD_YEAR : PERIOD_MONTH
  const isYearlyToMonthly = interval === 'year' && period === PERIOD_MONTH
  const isSamePlan = selectedPlan.id === currentPlanId
  const isDisabled = isYearlyToMonthly || isSamePlan
  const isStripeCustomer = `${userProfile?.customerId}`?.startsWith('cus_')

  useEffect(() => {
    setPlanStage(checkPlanChange(currentPlanId, selectedPlan.id))
    setDownGradeList(downGradeFeatureList(defaultPlan, selectedPlan))
  }, [selectedPlan])

  useEffect(() => {
    interval && setPeriod(activeInterval)
  }, [interval])

  const getCurrentStage = (stage) => {
    switch (stage) {
      case UPGRADE:
        return 'You will get:'
      case DOWNGRADE:
        return 'You will lose:'
      case CURRENT_PLAN:
        return 'Your current plan:'
      default:
        break
    }
  }

  const checkDowngradeEligibility = (selectedPlan, userProfile, liveSites) => {
    const issues = {
      projectsToArchive: [],
      customDomains: [],
      selectedPlan
    }

    let downgradeEligible = true
    const tiiny_plan = selectedPlan.label === 'Tiny'
    // Check project limits
    const allowedProjectCount = selectedPlan.projectLimit
    const fileSizeLimit = selectedPlan.fileSizeLimit * 1024 * 1024

    // Identify over-sized projects with customDomain
    const oversizedProjects = liveSites.filter((site) => site.size > fileSizeLimit)
    const sortedProjects = liveSites.sort((a, b) => a.created - b.created)

    // Identify projects with customDomain
    const projectsWithCustomDomains = tiiny_plan
      ? liveSites.filter((project) => project.isCustomDomain && project.email === userProfile.email)
      : []
    // Identify projects with password protection or email gate enabled
    const restrictedProjects = tiiny_plan
      ? liveSites.filter((site) => site.passwordProtected || site.enableEmailGate)
      : []

    // Filter out the projects that are oversized, have custom domains, or are restricted
    const filteredProjects = liveSites.filter(
      (project) =>
        !oversizedProjects.includes(project) &&
        !projectsWithCustomDomains.includes(project) &&
        !restrictedProjects.includes(project)
    )

    // Get the projects to keep
    const projectsToKeep = filteredProjects.slice(0, allowedProjectCount)
    const projectsToArchive = Array.from(
      new Set([
        ...oversizedProjects,
        ...projectsWithCustomDomains,
        ...restrictedProjects,
        ...sortedProjects.filter((project) => !projectsToKeep.includes(project))
      ])
    )

    if (projectsToArchive.length > 0) {
      downgradeEligible = false
      issues.projectsToArchive.push({
        projects: projectsToArchive.map((site) => ({
          subdomain: site.subdomain,
          fileSize: formatFileSize(site.size)
        })),
        allowedProjectCount
      })
    }

    // Check custom domains
    if (tiiny_plan) {
      if (customDomains.length > 0) {
        downgradeEligible = false
        issues.customDomains.push({
          projectsWithCustomDomains: customDomains.map((project) => project.domain)
        })
      }
    }

    return downgradeEligible ? { eligible: true, issues: {} } : { eligible: false, issues }
  }

  const onSubmitDownGrade = (selectedPlan, period) => {
    const planPrices = {
      priceId: selectedPlan?.prices?.[period]?.stripePriceId,
      paddleId: selectedPlan?.prices?.[period]?.paddleId,
      payProId: selectedPlan?.prices?.[period]?.payProId,
      productId: selectedPlan?.id
    }
    onDowngradeSubscription(selectedPlan, planPrices, period)
  }

  const onSubmit = (selectedPlan, period) => {
    if (planStage === DOWNGRADE) {
      if (isStripeCustomer) {
        const result = checkDowngradeEligibility(selectedPlan, userProfile, liveSites)
        if (!result.eligible) {
          setDowngradeIssues(result.issues)
          setIsDowngradeCheckList(true) // show checklist
        } else onSubmitDownGrade(selectedPlan, period)
      } else {
        onSubmitDownGrade(selectedPlan, period)
      }
    } else {
      onConfirm({ plan: `${getPriceLabel(selectedPlan, period)} ${period}`, period })
    }
  }

  return (
    <>
      {isDowngradeCheckList ? (
        <DowngradeChecklist
          downgradeIssues={downgradeIssues}
          onSubmitDownGrade={onSubmitDownGrade}
          period={period}
          isLoading={isLoading}
        />
      ) : (
        <>
          <h4 className="mb-2 bold color-purple-2">
            Change plan to <PlanSelectionDropdown defaultPlanId={selectedPlan.id} onPlanSelected={onPlanChange} />
          </h4>
          <div className="mt-3">{getCurrentStage(planStage)}</div>
          <FeatureList planId={selectedPlan.id} stage={planStage} list={downGradeList} />
          <div className="d-block">
            <div className="p-4 flex">
              {selectedPlan.prices.monthly && (
                <Button
                  className="flex-grow-1"
                  variant={period === PERIOD_MONTH ? 'outline-primary' : 'outline-secondary'}
                  onClick={() => setPeriod(PERIOD_MONTH)}
                >
                  <div className="price">
                    <h6>Monthly</h6>
                    <div>
                      {getPriceLabel(selectedPlan, PERIOD_MONTH)} <span className="price-period">/ month</span>
                    </div>
                  </div>
                </Button>
              )}
              {selectedPlan.prices.yearly && (
                <Button
                  className="flex-grow-1 ms-2"
                  variant={period === PERIOD_YEAR ? 'outline-primary' : 'outline-secondary'}
                  onClick={() => setPeriod(PERIOD_YEAR)}
                >
                  <div className="price">
                    <h6>Yearly</h6>
                    <div>
                      {getPriceLabel(selectedPlan, PERIOD_YEAR)} <span className="price-period">/ year</span>
                    </div>
                    {[PLANS.PRO.id, PLANS.PRO_U.id].includes(selectedPlan.id) && (
                      <div className="price-period mt-1">(2 months free)</div>
                    )}
                  </div>
                </Button>
              )}
            </div>
            {isSamePlan && activeInterval === period ? (
              <p className="bold">This is your current plan.</p>
            ) : isYearlyToMonthly ? (
              <p className="op-6" style={{ color: 'red', fontSize: '12px' }}>
                *Interval changes are only allowed at the end of the current billing period*
              </p>
            ) : (
              <>
                {planStage === DOWNGRADE ? (
                  <p>
                    If you choose to downgrade, your account will remain on the current plan until your next billing
                    cycle.
                  </p>
                ) : (
                  <p>
                    You will be charged the difference now, then{' '}
                    <b>
                      {getPriceLabel(selectedPlan, period)} {period.toLowerCase()}
                    </b>
                    <br />
                    <span className="op-6">We will automatically charge your current payment method.</span>
                  </p>
                )}
              </>
            )}
          </div>
          <div>
            <Button
              className="ms-auto me-auto mt-2 float-right"
              type="submit"
              disabled={isLoading || isDisabled}
              onClick={() => onSubmit(selectedPlan, period)}
            >
              {planStage === DOWNGRADE ? 'Downgrade' : 'Confirm'}
              {isLoading && <Spinner className="ms-2" animation="border" size="sm" />}
            </Button>
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ manage }) => ({
  userProfile: manage.userProfile,
  liveSites: manage.liveSites,
  customDomains: manage.customDomains
})

export default connect(mapStateToProps, null)(PlanView)
