import React, { useRef, useState, useEffect } from 'react'
import MoreOptions from './../MoreOptions/MoreOptions'
import styles from './OverflowMenu.module.css'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import useCheckIsMobileScreen from 'hooks/checkMobileScreen'
import { isDevSite } from 'utils/general'

const OverflowMenu = ({
  onEditHTML,
  onEditPDF,
  onArchive,
  onDownload,
  onAnalytics,
  onQRCode,
  onDelete,
  onCopy,
  onCopyDirectLink,
  onReplaceFile,
  onAddCustomDomain,
  type,
  onEmbed,
  passwordProtected,
  enableEmailGate,
  link,
  linkPreview,
  onShare,
  trial,
  disabled,
  onUpgrade,
  icon
}) => {
  const [show, setShow] = useState(false)
  const ref = useRef(null)
  const { isMobile, isTablet } = useCheckIsMobileScreen()

  const handleClick = () => {
    setShow(!show)
  }

  const handleBlur = () => {
    if (process.env.REACT_APP_ENABLE_E2E !== 'true') setShow(false)
  }

  const handleHover = () => {
    setShow(true)
  }

  const onCustomisePasswordPage = () => {
    if (isDevSite()) {
      window.open(`https://www.tiiny.co.uk/editor/?domain=${link}&file=/_auth/index.html&customize-auth=true`, '_blank')
    } else {
      window.open(`https://tiiny.host/editor/?domain=${link}&file=/_auth/index.html&customize-auth=true`, '_blank')
    }
  }

  return (
    <div
      className={styles.overflowMenu}
      ref={ref}
      onClick={handleClick}
      onMouseLeave={handleBlur}
      onMouseOver={!isMobile ? handleHover : null}
      tabIndex={0}
    >
      <OverlayTrigger
        trigger={[]}
        key={'left'}
        placement={'left'}
        show={show}
        overlay={
          <Popover id={`popover-positioned-left`} className={styles.container}>
            <Popover.Body className={styles.popoverContainer}>
              <MoreOptions
                onEditHTML={onEditHTML}
                onEditPDF={onEditPDF}
                onEmbed={onEmbed}
                onArchive={onArchive}
                onDownload={onDownload}
                onAnalytics={onAnalytics}
                onQRCode={onQRCode}
                onDelete={onDelete}
                onCopy={onCopy}
                onCopyDirectLink={onCopyDirectLink}
                onReplaceFile={onReplaceFile}
                onCustomisePasswordPage={onCustomisePasswordPage}
                onAddCustomDomain={onAddCustomDomain}
                type={type}
                passwordProtected={passwordProtected}
                enableEmailGate={enableEmailGate}
                linkPreview={linkPreview}
                onShare={onShare}
                trial={trial}
                link={link}
                onUpgrade={onUpgrade}
                disabled={disabled}
              />
            </Popover.Body>
          </Popover>
        }
      >
        <img src={icon} height={34} data-testid="OverflowMenu-icon-menu" />
      </OverlayTrigger>
    </div>
  )
}

export default OverflowMenu
