import { Modal, CloseButton } from 'react-bootstrap'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import useCheckIsMobileScreen from '../../hooks/checkMobileScreen'

const CustomModal = ({ children, show, mobileCloseButton = true, onHide, ...props }) => {
  const [zIndex, setZIndex] = useState(1050)
  const { isMobile } = useCheckIsMobileScreen()
  const modalRef = useRef(null)
  const backdropRef = useRef(null)
  const observerRef = useRef(null)

  const updateModalStack = useCallback(() => {
    const openModals = Array.from(document.querySelectorAll('.modal.show'))
    if (!openModals.length) return

    // Find our modal's index in the stack
    if (!modalRef.current) return
    const modalIndex = openModals.indexOf(modalRef.current)
    if (modalIndex === -1) return

    // Calculate new z-index based on position in stack
    const newZIndex = 1050 + modalIndex * 10
    setZIndex(newZIndex)

    if (backdropRef.current) {
      backdropRef.current.style.zIndex = `${newZIndex - 5}`
    }
  }, [])

  // Setup observer when component mounts
  useEffect(() => {
    observerRef.current = new MutationObserver((mutations) => {
      const relevantChanges = mutations.some(
        (mutation) =>
          Array.from(mutation.addedNodes).some(
            (node) => node.classList && (node.classList.contains('modal') || node.classList.contains('modal-backdrop'))
          ) ||
          Array.from(mutation.removedNodes).some(
            (node) => node.classList && (node.classList.contains('modal') || node.classList.contains('modal-backdrop'))
          )
      )

      if (relevantChanges) {
        updateModalStack()
      }
    })

    observerRef.current.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['class']
    })

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [updateModalStack])

  useEffect(() => {
    if (show && modalRef.current) {
      // Look for the backdrop that was just added
      const findBackdrop = () => {
        const backdrops = Array.from(document.querySelectorAll('.modal-backdrop.show'))
        if (backdrops.length > 0) {
          backdropRef.current = backdrops[backdrops.length - 1]
          updateModalStack()
        }
      }

      // We need a slight delay to ensure the backdrop is in the DOM
      const timer = requestAnimationFrame(findBackdrop)
      return () => cancelAnimationFrame(timer)
    }
  }, [show, updateModalStack])

  const handleEntered = (e) => {
    if (props.onEntered) {
      props.onEntered(e)
    }
    updateModalStack()
  }

  // Check if there's a Modal.Header among the children
  const hasModalHeader = React.Children.toArray(children).some((child) => {
    if (child && child.type === Modal.Header) return true

    // Check for nested Modal.Header
    if (child && child.props && child.props.children) {
      return React.Children.toArray(child.props.children).some(
        (grandChild) => grandChild && grandChild.type === Modal.Header
      )
    }

    return false
  })

  // Process children to modify Modal.Header props if it exists
  const processedChildren = React.Children.map(children, (child) => {
    // Skip null or undefined children
    if (!child) return child

    // Handle Modal.Header components
    if (child.type === Modal.Header) {
      // Determine if we should show closeButton based on existing prop and mobile status
      const shouldShowCloseButton =
        // Keep existing closeButton if it's explicitly set to true
        child.props.closeButton === true ||
        // Show closeButton on mobile if mobileCloseButton prop is true
        (isMobile && mobileCloseButton)

      // Create new style with no border bottom
      const newStyle = {
        ...(child.props.style || {}),
        borderBottom: 'none'
      }

      // Return the modified header
      return React.cloneElement(child, {
        closeButton: shouldShowCloseButton,
        style: newStyle
      })
    }

    // Recursively process nested children
    if (child.props && child.props.children) {
      return React.cloneElement(child, {
        children: React.Children.map(child.props.children, (grandChild) => {
          if (grandChild && grandChild.type === Modal.Header) {
            const shouldShowCloseButton = grandChild.props.closeButton === true || (isMobile && mobileCloseButton)

            const newStyle = {
              ...(grandChild.props.style || {}),
              borderBottom: 'none'
            }

            return React.cloneElement(grandChild, {
              closeButton: shouldShowCloseButton,
              style: newStyle
            })
          }
          return grandChild
        })
      })
    }

    return child
  })

  // Determine if we need to add a floating close button
  const showFloatingCloseButton = isMobile && mobileCloseButton && !hasModalHeader

  return (
    <Modal ref={modalRef} show={show} onHide={onHide} {...props} style={{ zIndex }} onEntered={handleEntered}>
      {showFloatingCloseButton && (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '10px 10px 0'
          }}
        >
          <CloseButton onClick={onHide} />
        </div>
      )}
      {processedChildren}
    </Modal>
  )
}

export default CustomModal
