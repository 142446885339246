import React from 'react'
import { Dropdown } from 'react-bootstrap'
import IconButton from '../../../IconButton'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <IconButton
    ref={ref}
    src="/assets/icons/edit.svg"
    tooltip="Update"
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  />
))

const UpdateActionDropdown = ({ site, onEditPDF, onEditHTML, onReplaceFile }) => {
  const isPDF = ['application/pdf', 'PDF'].includes(site.type)
  const isHTML =
    ['HTML', 'ZIP', 'EXAMPLE', 'text/html', 'application/zip'].includes(site.type) && !site.siteType?.includes('TEXT')
  const isPHP = site.subdomain?.includes('tiiny.io')

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
      <Dropdown.Menu style={{ borderRadius: 16 }} align={{ lg: 'start' }}>
        <Dropdown.Item onClick={() => onReplaceFile(site)} className="ps-4 pe-4 pt-3 pb-3">
          <img src={`/assets/icons/replace.svg`} height={20} className="me-3" />
          Update
        </Dropdown.Item>
        {isPDF && (
          <Dropdown.Item onClick={() => onEditPDF(site)} className="ps-4 pe-4 pt-3 pb-3">
            <img src={`/assets/icons/edit.svg`} height={20} className="me-3" />
            Edit PDF text
          </Dropdown.Item>
        )}
        {!isPHP && isHTML && (
          <Dropdown.Item onClick={() => onEditHTML(site)} className="ps-4 pe-4 pt-3 pb-3">
            <img src={`/assets/icons/edit.svg`} height={20} className="me-3" />
            Edit site
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UpdateActionDropdown
