import { ACTION_UPDATE, ACTION_EXTEND } from './interface'
import { createSite, getSiteContents, updateSiteContents } from 'services/v2/pro'
import { isEmpty } from 'utils/general'

/**
 * Query
 */

export const getSite = async (action, subdomain) => {
  switch (action) {
    case ACTION_UPDATE: {
      if (!subdomain) {
        return {
          link: null,
          siteFiles: []
        }
      }

      try {
        const { data } = await getSiteContents({ domain: subdomain })

        const { link, files } = data

        return {
          link,
          siteFiles: files
        }
      } catch (error) {
        throw new Error(error)
      }
    }
    case ACTION_EXTEND: {
      break
    }
    default: {
      return {
        link: null,
        files: []
      }
    }
  }
}

/**
 * Mutation
 */

export const updateSite = async (domain, values, executeRecaptcha, uploadProgress, isPresignedUpload) => {
  try {
    const { filesToAdd, indexFile, isZipPhpFile } = values
    const formData = new FormData()

    let link, files

    for (let i = 0; i < filesToAdd?.length; i++) {
      if (filesToAdd[i].isTemplate === true) {
        formData.append('template', filesToAdd[i].path)
      } else {
        formData.append('filesToAdd', filesToAdd[i])
      }
    }

    Object.keys(values).forEach((key) => {
      if (key !== 'filesToAdd' && !isEmpty(values[key])) {
        if (key === 'filesToRemove') {
          for (let i = 0; i < values[key].length; i++) {
            formData.append('filesToRemove', JSON.stringify({ id: values[key][i].id, size: values[key][i].size }))
          }
        }

        if (key === 'siteSettings') {
          formData.append(key, JSON.stringify(values[key]))
        }

        if (key === 'newDomain') {
          formData.append(key, values[key])
        }

        if (key === 'indexFile') {
          formData.append('indexFile', indexFile)
        }

        if (key === 'siteType') {
          formData.append(key, values[key])
        }

        if (key === 'isZipPhpFile') {
          formData.append('isZipPhpFile', isZipPhpFile)
        }
      }
    })

    const recaptchaValue = await executeRecaptcha('pro')
    const headers = {
      'x-recaptcha': recaptchaValue
    }

    const { data } = await updateSiteContents(domain, formData, headers, uploadProgress, isPresignedUpload)

    link = data.link
    files = data.files

    return {
      link: link,
      files: files
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const createNewSite = async (values, executeRecaptcha, uploadProgress) => {
  try {
    const { filesToAdd, indexFile, isZipPhpFile } = values
    const formData = new FormData()

    let link, files

    for (let i = 0; i < filesToAdd?.length; i++) {
      if (filesToAdd[i].isTemplate === true) {
        formData.append('template', filesToAdd[i].path)
      } else {
        formData.append('filesToAdd', filesToAdd[i])
      }
    }

    Object.keys(values).forEach((key) => {
      if (key !== 'filesToAdd' && !isEmpty(values[key])) {
        if (key === 'siteSettings') {
          formData.append(key, JSON.stringify(values[key]))
        }

        if (key === 'newDomain') {
          formData.append(key, values[key])
        }

        if (key === 'domainSuffix') {
          formData.append(key, values[key])
        }

        if (key === 'indexFile') {
          formData.append('indexFile', indexFile)
        }

        if (key === 'siteType') {
          formData.append(key, values[key])
        }

        if (key === 'isZipPhpFile') {
          formData.append('isZipPhpFile', isZipPhpFile)
        }
      }
    })

    const recaptchaValue = await executeRecaptcha('pro')
    const headers = {
      'x-recaptcha': recaptchaValue
    }

    const { data } = await createSite(formData, headers, uploadProgress)

    link = data.link
    files = data.files

    return {
      link: link,
      files: files
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
