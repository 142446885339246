import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { resumeSubscription } from '../../../services/manage'
import { ACCOUNT_STATUS } from '../../../constants'

import styles from './SubscriptionPaused.module.css'

function SubscriptionPausedAlert({ profile, refreshProfile }) {
  if (profile.status !== ACCOUNT_STATUS.PAUSED) return null

  const [isProcessing, setIsProcessing] = useState(false)

  const onResumeSubscription = async () => {
    setIsProcessing(true)
    try {
      await resumeSubscription()
      alert('Your subscription has resumed')
      refreshProfile()
    } catch {
      alert('Unable to resume your subscription')
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <div className={`${styles.container} p-4 mb-5`}>
      <div className="text-start font-24 bold">
        <img src="/assets/icons/warning_black.svg" height={22} width={22} className="me-2" />
        Your subscription is currently paused
      </div>
      <div className="flex flex-column gap-2">
        <div className="font-18 text-start mt-2">
          Your projects have been archived. Please resume your subscription to use your account.
        </div>
        <Button
          className="ms-auto d-block mt-1 bold"
          variant="warning"
          disabled={isProcessing}
          onClick={onResumeSubscription}
        >
          {isProcessing ? <Spinner animation="border" size="sm" /> : 'RESUME SUBSCRIPTION'}
        </Button>
      </div>
    </div>
  )
}

export default SubscriptionPausedAlert
