import React from 'react'
import CustomTooltip from 'components/CustomTooltip'
import { FILE_TYPE_PHP } from '../CreateUpdateSiteModal/interface'

import './SelectedFile.css'

export const FILE_TYPE_PDF = 'application/pdf'
export const FILE_TYPE_HTML = 'text/html'

const FILE_ICONS = {
  [FILE_TYPE_HTML]: 'html-file.png',
  [FILE_TYPE_PDF]: 'pdf-file.png',
  [FILE_TYPE_PHP]: 'php-file.png',
  'application/zip': 'zip-folder.png',
  'application/x-zip-compressed': 'zip-folder.png',
  'application/octet-stream': 'zip-folder.png'
}

function formatFileSize(file) {
  const bytes = file.size
  const sizeInMB = bytes / (1024 * 1024)

  if (sizeInMB < 0.01) {
    const sizeInKB = bytes / 1024
    return `${sizeInKB.toFixed(2)} KB`
  } else if (sizeInMB < 1024) {
    return `${sizeInMB.toFixed(2)} MB`
  } else {
    const sizeInGB = sizeInMB / 1024
    return `${sizeInGB.toFixed(2)} GB`
  }
}

const SelectedFile = ({ file, indexFile, className = '', onRemove, leftAlign }) => {
  const icon = FILE_ICONS[file.type || file.fileType] || 'file-x.svg'
  const fileName = file.path || file.name || file.fileName
  const text = fileName + (fileName === indexFile ? ' (index)' : '')

  return (
    <>
      <div className={`selected-file-container p-4 ${className}`}>
        <div className={`zip-file ${leftAlign ? 'left-align' : ''}`}>
          <div className="flex-grow-1 text-start flex align-items-center">
            <img className="file-icon" src={`/assets/icons/${icon}`} alt="Zip" />
            <div className="text-truncate">
              <CustomTooltip label={text} placement="bottom-start">
                <span className="font-20 truncate">{text}</span>
              </CustomTooltip>
              {!!file.size && <div className="font-small grey file-size">{formatFileSize(file)}</div>}
            </div>
          </div>

          {file.type === FILE_TYPE_HTML && (
            <CustomTooltip label="Will be used as your site's homepage (index.html)">
              <img className="ms-2 me-3" src="/assets/icons/info.svg" height="15" />
            </CustomTooltip>
          )}
          <div className="cursor-pointer d-inline ms-3" onClick={onRemove}>
            <img className="icon-delete" src="/delete.png" alt="delete" height="12" />
          </div>
        </div>
        {file.type === FILE_TYPE_HTML && (
          <div className={`${leftAlign ? 'text-start' : 'text-center'} font-small grey mt-2`}>
            Need to upload more files? Upload a zip file instead.
          </div>
        )}
      </div>
    </>
  )
}

export default SelectedFile
