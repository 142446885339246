import React, { useEffect, useRef, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import WebViewer from '@pdftron/webviewer'
import { fetchUserData } from '../../Manage/actions'
import { updatePDF } from 'services/update-site'
import CustomModal from 'components/CustomModal/CustomModal'

const LIC_KEY =
  'TIINY LABS LIMITED:OEM:Tiiny Host::B+:AMS(20260316):65CC2F62076A24F3BB313AC9A24338264E6F4F445E66670E40966918A7E92DD90931F5C7'

function PDFEditor({ document, dispatch, setIsPublishing, isTrial }) {
  const viewerRef = useRef(null)

  const pushPDFUpdate = async (updatedDocumentData) => {
    setIsPublishing(true)
    const fileName = document.split('/').pop()
    const body = new FormData()
    const blob = new Blob([updatedDocumentData], { type: 'application/pdf' })
    body.append('pdf', blob, fileName)
    body.append('document', document)

    updatePDF(body)
      .then(() => {
        alert('Document updated successfully')
        dispatch(fetchUserData())
      })
      .catch((err) => {
        alert('Sorry, an error occurred')
        console.error(err)
      })
      .finally(() => setIsPublishing(false))
  }

  useEffect(() => {
    WebViewer(
      {
        path: 'https://tiiny.host/webviewer/lib',
        initialDoc: document,
        licenseKey: LIC_KEY,
        fullAPI: true
      },
      viewerRef.current
    ).then((instance) => {
      const { Core, UI } = instance

      UI.enableFeatures([instance.UI.Feature.ContentEdit])

      UI.setToolbarGroup(instance.UI.ToolbarGroup.EDIT_TEXT)
      Core.documentViewer.addEventListener('documentLoaded', () => {})

      const publishButton = new UI.Components.CustomButton({
        dataElement: 'publishButton',
        className: 'btn btn-primary',
        label: 'Publish',
        title: 'Publish PDF',
        style: {
          backgroundColor: '#0d6efd',
          color: '#FFFFFF'
        },
        onClick: async () => {
          try {
            const doc = await Core.documentViewer.getDocument().getFileData({
              xfdfString: await Core.annotationManager.exportAnnotations()
            })
            await pushPDFUpdate(doc)
          } catch (error) {
            console.error('Error during publishing:', error)
          }
        }
      })

      const defaultHeader = UI.getModularHeader('default-top-header')
      const items = [...defaultHeader.items]

      if (!isTrial) {
        items.push(publishButton)
      }

      defaultHeader.setItems(items)
    })
  }, [document])

  return <div className="w-100 vh-80" ref={viewerRef} />
}

function PDFEditorModal({ document, show, onClose, dispatch, trial, onUpgrade }) {
  const [isPublishing, setIsPublishing] = useState(false)

  return (
    <CustomModal show={show} size="xl" centered enforceFocus={false}>
      <div className="p-3 bg-purple text-white flex align-items-center">
        <h2 className="font-20 d-inline flex-1 mb-0">PDF Editor</h2>
        {trial && (
          <Button className="me-3 ps-5 pe-5" variant="purple" size="sm" onClick={onUpgrade}>
            Upgrade to publish
          </Button>
        )}
        <button className="transparent-button" onClick={onClose}>
          <img src="/assets/icons/close-white.svg" height={20} width={20} alt="close" />
        </button>
        {isPublishing && <Spinner className="mt-1 me-2 float-right" size="sm" animation="border" variant="light" />}
      </div>
      <PDFEditor document={document} dispatch={dispatch} setIsPublishing={setIsPublishing} isTrial={trial} />
    </CustomModal>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(undefined, mapDispatchToProps)(PDFEditorModal)
