import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { isIndia } from '../ManageAccountModal/components/Details'
import { initializeCalEmbed } from '../../utils/calEmbed'

import './FeedbackCallModal.css'

const FeedbackCallModal = ({ userCreatedTime }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    checkAndShowBookCallModal()
  }, [userCreatedTime])

  const checkAndShowBookCallModal = () => {
    const hideBookCallModal = localStorage.getItem('HIDE_FEEDBACK') === 'true'

    if (hideBookCallModal || isIndia) {
      return
    }

    const managePageVisits = parseInt(localStorage.getItem('manage-page-visits') || '0')
    localStorage.setItem('manage-page-visits', (managePageVisits + 1).toString())

    if (managePageVisits >= 4) {
      if (userCreatedTime) {
        const createdDate = new Date(userCreatedTime)
        const currentDate = new Date()
        const daysSinceCreation = (currentDate - createdDate) / (1000 * 60 * 60 * 24)

        if (daysSinceCreation >= 1) {
          initializeCalEmbed()
          setShow(true)
        }
      }
    }
  }

  const onClose = () => {
    localStorage.setItem('HIDE_FEEDBACK', 'true')
    localStorage.removeItem('manage-page-visits')
    setShow(false)
  }

  return (
    <Modal show={show} onHide={onClose} centered className="book-call-modal" size="md">
      <Modal.Header closeButton>
        <Modal.Title className="color-purple-2 font-26">You've been selected️ ✨</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-4">
          <div className="profile-image-container mb-3">
            <img src="/assets/img/kourosh-profile.jpg" alt="Kourosh profile" className="rounded-circle profile-image" />
          </div>
          <p className="mt-3 bold font-18 dark-grey">Hey! I'm Kourosh, a product manager at Tiiny.</p>
          <p className="mt-3 font-16 grey">
            <b>To help us improve, we'd love your feedback!</b>
          </p>
        </div>
        <div className="text-center mt-4">
          <Button
            variant="primary"
            size="lg"
            className="book-call-btn w-100"
            data-cal-link="kourosh/15min"
            data-cal-namespace="15min"
            data-cal-config='{"layout":"month_view","theme":"light"}'
          >
            Book 15-min call
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FeedbackCallModal
