import React from 'react'
import { Spinner } from 'react-bootstrap'
import CustomTooltip from '../../../CustomTooltip'
import styles from './StatusCell.module.css'

const statusTypes = {
  active: { color: 'green', icon: '/assets/icons/circle-green.svg' },
  error: { color: 'red', icon: '/assets/icons/circle-red.svg' },
  processing: { color: 'grey', icon: null },
  disabled: { color: 'red', icon: '/assets/icons/circle-red.svg' },
  danger: { color: 'red', icon: '/assets/icons/circle-red.svg' },
  online: { color: 'green', icon: '/assets/icons/circle-green.svg' },
  undefined: { color: 'green', icon: '/assets/icons/circle-green.svg' },
  archived: { color: 'grey', icon: '/assets/icons/archive-box.svg' },
  validated: { color: 'green', icon: '/assets/icons/circle-green.svg' },
  pending: { color: 'orange', icon: '/assets/icons/circle-orange.svg' },
  initiated: { color: 'blue', icon: '/assets/icons/circle-blue.svg' },
  failed: { color: 'red', icon: '/assets/icons/circle-red.svg' },
  preview: { color: 'grey', icon: '/assets/icons/circle-grey.svg' }
  // Add more status types as needed
}

const StatusIcon = ({ status }) => {
  const statusInfo = statusTypes[status] || statusTypes.active

  if (status === 'processing') {
    return (
      <CustomTooltip label="Processing">
        <Spinner animation="grow" variant="secondary" size="sm" />
      </CustomTooltip>
    )
  }

  return statusInfo.icon ? <img src={statusInfo.icon} height="10px" alt={status} /> : null
}

const StatusCell = ({ status, text, passwordProtected }) => {
  const statusInfo = statusTypes[status] || statusTypes.active

  return (
    <div className={styles.container}>
      <div className={`${styles.statusBadge} ${styles[statusInfo.color]}`}>
        <StatusIcon status={status} />
        <span className={styles.statusText} data-testid="StatusCell-span-status-text" >{text}</span>
      </div>
      {passwordProtected && (
        <CustomTooltip label="Password protected">
          <img src="lock-link.svg" height="16px" alt="Password protected" className={styles.lockIcon} />
        </CustomTooltip>
      )}
    </div>
  )
}

export default StatusCell
