import React from 'react'
import moment from 'moment'
import LinkButton from '../LinkButton'
import { ARCHIVE_ENDPOINT } from '../../constants'
import { getS3Path, getSubdomainAndTLD } from 'utils/general'
import CustomTable from '../CustomTable'
import useCheckIsMobileScreen from '../../hooks/checkMobileScreen'

const getArchiveLabel = (siteRecord) => siteRecord.subdomain

const getArchiveDownloadLink = (archiveRecord) => {
  const customDomain = archiveRecord.isCustomDomain
  const s3Path = getS3Path({ site: archiveRecord, isCustomDomain: customDomain })
  return `${ARCHIVE_ENDPOINT}/${archiveRecord?.id}%23${s3Path}/__archive__/backup.zip`
}

const ArchiveTable = ({ sites, onDeleteArchive, onRestoreArchive }) => {
  const { isMobile } = useCheckIsMobileScreen()
  const headers = [
    { key: 'status', text: 'Status', icon: '', cellType: 'status' },
    { key: 'link', text: 'Link', icon: '', cellType: 'normal_text' },
    { key: 'lastModified', text: 'Created', icon: '', cellType: 'lastModified' },
    { key: 'actions', text: 'Actions', icon: '', cellType: 'actions' }
  ]

  const data = sites.map((s) => ({
    status: {
      status: 'archived',
      text: 'Archived'
    },
    link: getArchiveLabel(s),
    lastModified: s?.lastUpdated || s?.created ? moment(s?.lastUpdated || s?.created).format('DD MMM YY') : '-',
    actions: [
      {
        icon: '/assets/icons/trash.svg',
        tooltip: 'Delete',
        onClick: () => onDeleteArchive(s),
        label: isMobile ? 'Delete' : undefined
      },
      {
        icon: '/assets/icons/download-white.svg',
        tooltip: 'Download',
        onClick: () => window.open(getArchiveDownloadLink(s), '_blank'),
        label: isMobile ? 'Download' : undefined
      },
      {
        icon: '/assets/icons/cloud-upload-restore.svg',
        tooltip: 'Restore',
        onClick: () => onRestoreArchive(s),
        disabled: s.linkRecord ? false : true,
        label: isMobile ? 'Restore' : undefined
      }
    ]
  }))

  return (
    <div className="box-100-scrolless">
      {sites.length > 0 ? (
        <>
          <CustomTable title="Archived Sites" data={data} headers={headers} badge={''} headerButtons={[]} />
        </>
      ) : (
        <div className="table-empty">
          <div>
            <span className="light-grey bold">No archived projects</span>
          </div>
          <div className="mt-1 text-sm light-grey">Archived projects will appear here.</div>
        </div>
      )}
    </div>
  )
}

export default ArchiveTable
