import { API_ROOT } from '../../constants'
import api from '../../utils/api'
import axios from 'axios'
import JSZip from 'jszip'
import { uniqueNamesGenerator, colors, names, NumberDictionary } from 'unique-names-generator'
import { isVideoFile } from 'helpers/file-type'

export const MAX_DIRECT_UPLOAD_SIZE = 2 * 1024 * 1024 * 1024 // 2GB

export const getSafeFileName = (fileName) => {
  const index = fileName.lastIndexOf('.')
  const name = fileName.substring(0, index)
  const ext = fileName.substring(index + 1).toLowerCase()

  return `${name.replace(/[-\s.]+/g, '-').replace(/['?>~!@%()"{}&#!<>:`+]+/g, '')}.${ext}`
}

const handlePresignedUploads = async (files, domain, newDomain, formData, config, isUpdate, uploadProgress) => {
  try {
    newDomain = newDomain || domain
    const totalSize = files.reduce((acc, file) => acc + file.size, 0)
    let uploadedSize = 0
    const chunkProgress = {}

    const CHUNK_SIZE = 10 * 1024 * 1024 // 10MB chunks
    const filesToAddPresigned = []
    /** For S3 CompleteMultipartUploadCommand */
    const multipartInfo = []

    const processFilePromises = files.map(async (file) => {
      const formDataGetPresignedUrl = new FormData()
      const safeFileName = getSafeFileName(file.name)

      formDataGetPresignedUrl.delete('filesToAdd')
      formDataGetPresignedUrl.append('key', `${domain}/${safeFileName}`)
      formDataGetPresignedUrl.append('domain', domain)
      formDataGetPresignedUrl.append('newDomain', newDomain)

      const fileInfo = {
        name: safeFileName,
        originalFilename: safeFileName,
        s3FilePath: `${domain}/${safeFileName}`,
        type: file.type,
        size: file.size
      }
      filesToAddPresigned.push(fileInfo)
      formDataGetPresignedUrl.append('filesToAddPresigned', JSON.stringify([fileInfo]))

      const presignedUrlResponse = await api.post(
        `${API_ROOT}/v3/site/get-presigned-url/${isUpdate ? 'update' : 'create'}${isUpdate ? `?domain=${domain}` : ''}`,
        formDataGetPresignedUrl,
        config
      )
      const { presignedUrls, uploadId, key } = presignedUrlResponse.data

      /* Chunks for multipart upload */
      const chunks = Math.ceil(file.size / CHUNK_SIZE)
      const uploadPromises = []

      /** For storing ETag and PartNumber of completed multipart chunks */
      const completedParts = []

      for (let i = 0; i < chunks; i++) {
        const start = i * CHUNK_SIZE
        const end = Math.min(start + CHUNK_SIZE, file.size)
        const chunk = file.slice(start, end)
        const partNumber = i + 1

        const uploadChunk = async () => {
          chunkProgress[partNumber] = 0

          const response = await axios.put(presignedUrls[i], chunk, {
            headers: {
              'Content-Type': file.type,
              'Content-Length': chunk.size
            },
            transformRequest: [(data) => data],
            withCredentials: false,
            onUploadProgress: (progressEvent) => {
              const previousProgress = chunkProgress[partNumber] || 0
              const currentProgress = (progressEvent.loaded / progressEvent.total) * chunk.size
              const diff = currentProgress - previousProgress

              uploadedSize += diff
              chunkProgress[partNumber] = currentProgress

              if (uploadProgress) {
                const percentComplete = Math.min(Math.round((uploadedSize / totalSize) * 100), 99)
                uploadProgress({ loaded: percentComplete, total: 100 }, { size: totalSize }, true)
              }
            }
          })

          completedParts.push({
            ETag: response.headers.etag,
            PartNumber: partNumber
          })
        }

        uploadPromises.push(uploadChunk())
      }

      /** Upload multipart chunks to corresponding presigned urls */
      await Promise.all(uploadPromises)

      multipartInfo.push({
        key,
        uploadId,
        parts: completedParts.sort((a, b) => a.PartNumber - b.PartNumber)
      })
    })

    /** Process and complete multipart uploads with info of completed chunks */
    await Promise.all(processFilePromises)

    formData.delete('filesToAdd')
    if (isUpdate) formData.append('domain', domain)
    formData.append('newDomain', newDomain)
    formData.append('filesToAddPresigned', JSON.stringify(filesToAddPresigned))
    formData.append('multipartInfo', JSON.stringify(multipartInfo))

    return await api.post(
      `${API_ROOT}/v3/site/process-presigned-url/${isUpdate ? 'update' : 'create'}${isUpdate ? `?domain=${domain}` : ''}`,
      formData,
      {
        ...config,
        headers: {
          ...config.headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const generateRandomSubdomain = () => {
  const numberDictionary = NumberDictionary.generate({ min: 1, max: 99 })
  return uniqueNamesGenerator({
    dictionaries: [colors, names, numberDictionary],
    separator: '-',
    style: 'lowerCase'
  })
}

export const calculateTotalSize = async (files) => {
  let totalSize = 0

  for (const file of files) {
    if (file.name.toLowerCase().endsWith('.zip')) {
      try {
        const zip = new JSZip()
        const zipContents = await zip.loadAsync(file)

        for (const zipEntry of Object.values(zipContents.files)) {
          if (!zipEntry.dir) {
            totalSize += zipEntry._data.uncompressedSize
          }
        }
      } catch (error) {
        totalSize += file.size
      }
    } else {
      totalSize += file.size
    }
  }

  return totalSize
}

export const updateSiteContents = async (domain, body, config, uploadProgress, isPresignedUpload) => {
  try {
    const formData = body
    const filesToAdd = formData.getAll('filesToAdd')
    const totalSize = await calculateTotalSize(filesToAdd)
    const newDomain = formData.get('newDomain')

    /** Trigger presigned upload flow for all single video files */
    const isSingleVideoFile = filesToAdd?.length === 1 && filesToAdd.some((file) => isVideoFile(file.name, file.type))

    if (isPresignedUpload || totalSize > MAX_DIRECT_UPLOAD_SIZE || isSingleVideoFile) {
      return handlePresignedUploads(filesToAdd, domain, newDomain, formData, config, true, uploadProgress)
    }

    return await api.post(`${API_ROOT}/v3/site/update?domain=${domain}`, body, config, (event) => {
      const { total, loaded } = event
      uploadProgress({ total, loaded }, { size: total })
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const createSite = async (body, config, uploadProgress) => {
  try {
    const formData = body
    const filesToAdd = formData.getAll('filesToAdd')
    const domainSuffix = formData.get('domainSuffix')
    const domain =
      formData.get('newDomain') || generateRandomSubdomain() + (domainSuffix ? domainSuffix : '.tiiny.site')

    const totalSize = await calculateTotalSize(filesToAdd)

    /** Trigger presigned upload flow for all single video files */
    const isSingleVideoFile = filesToAdd?.length === 1 && filesToAdd.some((file) => isVideoFile(file.name, file.type))

    if (totalSize > MAX_DIRECT_UPLOAD_SIZE || isSingleVideoFile) {
      return handlePresignedUploads(filesToAdd, domain, domain, formData, config, false, uploadProgress)
    }

    return await api.post(`${API_ROOT}/v3/site/create`, body, config, (event) => {
      const { total, loaded } = event
      uploadProgress({ total, loaded }, { size: total })
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteSiteFiles = async (body, config) => await api.post(`${API_ROOT}/v3/site/delete`, body, config)

export const getSiteContents = async (body, config) => await api.get(`${API_ROOT}/v3/site`, body, config)

export const getSites = async (body, config) => await api.post(`${API_ROOT}/v3/site/webhook`, body, config)
