import { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'

const DowngradeChecklist = ({ downgradeIssues, onSubmitDownGrade, period, isLoading }) => {
  const selectedPlan = downgradeIssues?.selectedPlan

  return (
    <div>
      <div
        style={{
          padding: '20px',
          color: '#333'
        }}
      >
        <h2 className="font-20 bold">
          You will be downgraded to the <span className="mb-2 bold color-purple-2">{selectedPlan.label}</span> plan on
          your next billing cycle
        </h2>
        {/* Projects to Archive */}
        {downgradeIssues.projectsToArchive.length > 0 && (
          <div className="mt-4">
            {downgradeIssues.projectsToArchive.map((issue, index) => (
              <div key={`project-issue-${index}`}>
                <h3 className="font-18">These projects will be archived</h3>
                <p className="grey m-0">
                  The {selectedPlan.label} Plan only allows you to have {issue.allowedProjectCount}{' '}
                  {issue.allowedProjectCount > 1 ? `projects` : `project`}.
                </p>
                <p className="grey mb-1">These projects may contain features that are not allowed on this plan.</p>
                <ul>
                  {issue.projects.map((project, projectIndex) => (
                    <li key={`oversized-project-${projectIndex}`} className="font-16 bold">
                      {project.subdomain} ({project.fileSize})
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}

        {/* Custom Domains */}
        {downgradeIssues.customDomains.length > 0 && (
          <div className="mb-4">
            {downgradeIssues.customDomains.map((issue, index) => (
              <div key={`custom-domain-issue-${index}`}>
                <h3 className="font-18">Your custom domain connection will be deactivated</h3>
                <p className="grey m-0">Custom domains are only available on the Solo & Pro plans.</p>
                <p className="grey">You will still have access to your custom domain if you choose to upgrade again.</p>
                <ul>
                  {issue.projectsWithCustomDomains.map((domain, domainIndex) => (
                    <li key={`custom-domain-${domainIndex}`} className="font-16 bold">
                      {domain}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}

        <div>
          <Button
            className="ms-auto me-auto mt-2 float-right"
            type="submit"
            disabled={isLoading}
            onClick={() => onSubmitDownGrade(selectedPlan, period)}
          >
            Complete downgrade {isLoading && <Spinner className="ms-2" animation="border" size="sm" />}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DowngradeChecklist
